import { createI18n, type I18n, type I18nOptions } from 'vue-i18n';
import { nextTick } from 'vue';
import AppConfig from '@/constants';
import { loadLocalData, saveLocalData } from '@/utils/common-util';
import ko from './locale/ko.json';
import en from './locale/en.json';

// 사용 가능 언어
export const SUPPORT_LOCALES = ['ko', 'en'];

// i18n 객체 생성
export function setupI18n(options: I18nOptions = { locale: 'ko' }): I18n<any, any, any, any> {
	const i18n: any = createI18n(options);
	setI18nLanguage(i18n, options.locale || 'ko');
	return i18n;
}

// html문서에 언어 추가
export function setI18nLanguage(i18n: I18n<any, any, any, any>, locale: string): void {
	i18n.global.locale = locale;

	if (typeof window !== 'undefined') {
		const el = window.document.querySelector('html') as HTMLElement;
		el.setAttribute('lang', locale);
	}
}

// locale별 json 파일 불러오기
export async function loadLocaleMessages(i18n: I18n<any, any, any, any>, locale: string): Promise<void> {
	const messages = await import(`./locale/${locale}.json`);

	i18n.global.setLocaleMessage(locale, messages.default);

	return nextTick();
}

export { ko, en };
