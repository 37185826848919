<script lang="ts">
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { HeaderSelectOption } from '@/components/common/apocSelect';
import { PropType, defineComponent, ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

//콘텐츠 SelectBox
export default defineComponent({
	name: 'DynamicOptionSelect',
	components: { ApocImageSet },
	props: {
		optionList: {
			type: Array as PropType<HeaderSelectOption[]>,
			required: true,
		},
		placeholder: {
			type: String as PropType<string>,
		},
		//이미지
		placeholderImgSrc: {
			type: String as PropType<string>,
			default: '',
			required: false,
		},
		//아이콘
		placeholderIconSrc: {
			type: String as PropType<string>,
			default: '',
			required: false,
		},
		//아래 화살표 이미지
		arrowImgSrc: {
			type: String as PropType<string>,
			default: '',
			required: false,
		},
		onChange: {
			type: Function,
			default: () => ({}),
		},
		//true: select, false: button 으로 쓰임
		isSelect: {
			type: Boolean as PropType<boolean>,
			default: true,
		},
		//option 선택 후 css 변경(primary Color로) 원하면 true
		isActive: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		// option-list가 열리지 않도록
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		//purple arrow 사용 유무
		isPurple: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		//값 같을 때 보라색 체크 표시 유무
		isCheck: {
			type: Boolean as PropType<boolean>,
			default: true,
		},
		//팝업 - select box에서 쓰입니다.
		selectOptionParent: {
			type: Object as PropType<HTMLElement | undefined>,
			required: true,
		},
		//팝업 - select box에서 쓰입니다.
		align: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		//creator 화면에서 플리킹과 중첩되어 select box가 가려지는 경우 필요합니다.
		listItemWrapper: {
			type: Object as PropType<HTMLDivElement | undefined>,
			required: false,
			default: undefined,
		},
		// 스크롤 이벤트에 스크롤이 팝업이 움직이도록 할것인지
		enableScrollEvent: {
			type: Boolean,
			required: false,
			default: true,
		},
		// 리사이즈 이벤트에 따라 스크롤이 움직이도록 할것인지
		enableResizeEvent: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	setup(props) {
		const listOpened = ref<boolean>(false);
		const router = useRouter();
		const route = useRoute();
		const selectedValue = ref<string>(props.placeholder ? props.placeholder : '');
		const selectedValueImg = ref<string>(props.placeholderImgSrc ? props.placeholderImgSrc : '');
		const isCSSActive = ref<boolean>(false);
		const listItemWrapperElement = ref<HTMLDivElement | undefined>(props.listItemWrapper || undefined);

		//select list 위치 관련
		const parentElement = computed(() => props.selectOptionParent || window.document.body);
		const selectedValueElement = ref<HTMLElement | null>(null);
		const optionListElement = ref<HTMLElement | null>(null);
		const dropdownType = ref<'UP' | 'DOWN'>('DOWN');

		const handleSelect = (v?: HeaderSelectOption) => {
			if (v) {
				if (v.router) {
					router.push(v.router);
				}
				if (props.onChange) {
					props.onChange(v);
					// selectedValue.value = v.label;
				}
				if (props.isSelect && props.placeholderImgSrc) selectedValueImg.value = v.imageSrc as string;
				if (v.func) v.func();
				//select 조건으로 쓰일 경우에만 값 바뀌도록
				if (props.isSelect) selectedValue.value = v.label;
				//option 선택 후 css 변경 원하면
				if (props.isActive) isCSSActive.value = true;
			}
			listOpened.value = false;
		};
		const handleSelectPlaceholder = () => {
			listOpened.value = false;
		};
		const clickSelectBox = () => {
			//옵션 리스트가 열리도록 한 경우
			if (!props.disabled) {
				if (listOpened.value) {
					//닫는 경우
					listOpened.value = false;
					dropdownType.value = 'DOWN'; // 드롭다운 닫히는 경우 Down으로 초기화
					if (listItemWrapperElement.value) {
						listItemWrapperElement.value?.parentElement?.parentElement?.classList.remove('overflow-unset');
					}
				} else {
					//여는 경우
					listOpened.value = true;
					if (listItemWrapperElement.value) {
						listItemWrapperElement.value?.parentElement?.parentElement?.classList.add('overflow-unset');
					}
					setOptionListStyle(); // 드롭다운 열리는 경우 위치 재정의
				}
			}
		};
		const closeOptionList = () => {
			listOpened.value = false;
			if (listItemWrapperElement.value) listItemWrapperElement.value?.parentElement?.parentElement?.classList.remove('overflow-unset');
		};

		const handleScroll = () => {
			setOptionListStyle();
		};

		const handleResize = () => {
			setOptionListStyle();
		};

		//부모 요소에 따라 select list 위치 조정
		const setOptionListStyle = () => {
			if (selectedValueElement.value && optionListElement.value) {
				const selectedBoxStart = selectedValueElement.value.getBoundingClientRect().top;
				const selectedBoxEnd = selectedValueElement.value.getBoundingClientRect().bottom;
				const btnWrapperStart = (props.selectOptionParent as HTMLDivElement).getBoundingClientRect().bottom;
				const listBoxEnd = optionListElement.value.getBoundingClientRect().bottom;

				optionListElement.value.style.marginTop = '5px';
				optionListElement.value.style.marginBottom = '5px';
				optionListElement.value.style.left = window.getComputedStyle(props.selectOptionParent as HTMLDivElement).getPropertyValue('padding-left');
				optionListElement.value.style.width = selectedValueElement.value.getBoundingClientRect().width + 'px';
				if (dropdownType.value === 'DOWN') {
					if (selectedBoxEnd > btnWrapperStart) {
						//  selectedBox영역이 btnWapper에 침범되었는가
						//    optionList가 selectBox의 시작지점부터 생성된다.
						optionListElement.value.style.top =
							selectedValueElement.value.getBoundingClientRect().top -
							(props.selectOptionParent as HTMLDivElement).getBoundingClientRect().top +
							'px';
					} else {
						//    optionList가 selectBox의 끝지점부터 생성된다.
						optionListElement.value.style.top =
							selectedValueElement.value.getBoundingClientRect().bottom -
							(props.selectOptionParent as HTMLDivElement).getBoundingClientRect().top +
							'px';
					}
				}
				if (dropdownType.value === 'UP') {
					if (selectedBoxStart > btnWrapperStart) {
						//  selectedBox의 시작지점이 btnWapper 보다 아래 있는 경우
						//    optionList가 boxWrapper의 시작점에 고정된다
						optionListElement.value.style.top =
							btnWrapperStart -
							optionListElement.value.getBoundingClientRect().height -
							(props.selectOptionParent as HTMLDivElement).getBoundingClientRect().top +
							'px';
					} else {
						//    optionList가 selectBox의 시작점에 고정된다
						optionListElement.value.style.top =
							selectedValueElement.value.getBoundingClientRect().top -
							optionListElement.value.getBoundingClientRect().height -
							(props.selectOptionParent as HTMLDivElement).getBoundingClientRect().top +
							'px';
					}
				}
				if (listBoxEnd > 0 && listBoxEnd > btnWrapperStart) {
					//  optionList의 끝지점이 btnWapper 보다 아래 있는 경우
					//    스크롤이 상위 드롭다운으로 변경된다
					dropdownType.value = 'UP';
				}
			}
		};

		watch(
			() => props.selectOptionParent,
			() => {
				if (props.enableScrollEvent) {
					parentElement.value.removeEventListener('scroll', handleScroll);
					// parentElement.value = props.selectOptionParent || window.document.body;
					parentElement.value.addEventListener('scroll', handleScroll);
				}
			},
		);

		watch(
			() => optionListElement.value,
			() => {
				if (optionListElement.value) {
					setOptionListStyle();
				}
			},
		);

		watch(
			() => props.placeholder,
			(current, prev) => {
				selectedValue.value = current as string;
			},
		);

		watch(
			() => props.placeholderImgSrc,
			(current, prev) => {
				selectedValueImg.value = current as string;
			},
		);
		watch(route, () => {
			// 주소가 바뀌면 드롭다운 메뉴가 무조건 닫히도록
			closeOptionList();
		});

		onMounted(() => {
			if (props.enableResizeEvent) {
				window.addEventListener('resize', handleResize);
			}
		});

		onUnmounted(() => {
			if (props.enableResizeEvent) {
				window.removeEventListener('resize', handleResize);
			}

			if (props.enableScrollEvent) {
				parentElement.value.removeEventListener('scroll', handleScroll);
			}
		});

		return {
			listOpened,
			selectedValue,
			selectedValueImg,
			isCSSActive,
			selectedValueElement,
			optionListElement,
			handleSelect,
			clickSelectBox,
			closeOptionList,
			handleSelectPlaceholder,
		};
	},
});
</script>
<template>
	<div v-click-away="closeOptionList" class="apoc-select content-select" :class="isCSSActive ? 'active' : ''">
		<div ref="selectedValueElement" class="selected-area" @click="clickSelectBox">
			<apoc-image-set v-show="placeholderImgSrc" class="placeholder-img" :src="selectedValueImg" />
			<p class="selected-value"><i v-show="placeholderIconSrc" :class="placeholderIconSrc" />{{ selectedValue ?? '' }}</p>
			<div v-show="isSelect" class="select-arrow-down">
				<apoc-image-set
					:class="{ active: listOpened }"
					:src="
						arrowImgSrc
							? arrowImgSrc
							: isCSSActive || isPurple
								? '/assets/images/common/icons/arrow-down2-purple.svg'
								: '/assets/images/common/icons/arrow-down-large-navy.svg'
					" />
			</div>
		</div>
		<teleport v-if="selectOptionParent" to="#content-popup-options">
			<transition name="dropdown">
				<div v-show="listOpened" ref="optionListElement" class="dynamic-selector option-list-area-wrapper">
					<ul class="option-list-area">
						<li
							v-for="(option, index) of optionList"
							:key="option.value"
							:value="option.value"
							:class="`option-list-item-${index}`"
							@click="handleSelect(option)">
							<!-- 왼쪽 아이콘 이미지-->
							<apoc-image-set v-show="option.imageSrc" class="option-list-image" :src="option.imageSrc ?? ''" />
							<div class="option-list-desc">
								<!-- 타이틀 -->
								<span>{{ option.label }}</span>
								<!-- 부가 설명 -->
								<span v-show="option.subLabel">{{ option.subLabel }}</span>
							</div>
							<!-- 선택된 항목 체크 표시 -->
							<apoc-image-set
								v-if="isCheck && selectedValue === option.label"
								class="option-list-check"
								src="/assets/images/common/icons/mypage/icon_check-purple.svg" />
						</li>
					</ul>
				</div>
			</transition>
		</teleport>
	</div>
</template>
