import { CONTENT_EVAL_TYPE_CODE, CONTENT_STATE_CODE, STATE_YN } from '../../types';

/**
 * 컨텐트
 */
export class ContentEntity {
	// 콘텐트고유번호
	contentIdx = '';
	// 콘텐트명**
	contentName = '';
	// 콘텐트내용**
	contentBody = '';
	// 읽음횟수
	readCount = 0;
	// 플레이시간
	playTime = '';
	// 상품등록여부**
	productRegYn: STATE_YN = STATE_YN.N;
	// 요약설명
	summaryDesc = '';
	// 상세설명
	detailDesc = '';
	// 어린이여부**
	kidYn: STATE_YN = STATE_YN.N;
	// 시작페이지
	startPage = '';
	// 종료페이지
	endPage = '';
	// 회원업로드파일목록
	userUploadFileList = '';
	// 외부컨텐트연결여부**
	externalContentConnectYn: STATE_YN = STATE_YN.N;
	// 공개여부**
	openTypeCode = '';
	// 유료여부**
	chargeYn: STATE_YN = STATE_YN.N;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	channelIdx: string | undefined = undefined;
	shortUrl: string | undefined = undefined;
	//게시여부
	apocPostYn: STATE_YN = STATE_YN.N;
	// 컨텐트상태코드
	contentStateCode: CONTENT_STATE_CODE = CONTENT_STATE_CODE.ACTIVE;
	// 콘텐츠 파일용량
	contentStorage: number | null = null;
	// 소유자 고유번호
	ownerIdx: string | undefined = undefined;
}

/**
 * 컨텐트 내용
 */
export class ContentBodyEntity extends ContentEntity {
	// 컨텐트내용메타명**
	contentBodyMetaName = '';
	// 컨텐트고유번호**
	contentIdx = '';
	// 컨텐트내용메타값**
	contentBodyMetaValue = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

/**
 * 컨텐트 협업 내역
 */
export class ContentCollaboRecEntity extends ContentEntity {
	// 콘텐트고유번호
	contentCollaboSeq = 0;
	// 컨텐트고유번호**
	contentIdx = '';
	// 협업자회원고유번호**
	collaborUserIdx = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

/**
 * 컨텐트 댓글
 */
export class ContentCommentEntity extends ContentEntity {
	// 컨텐트댓글고유번호
	contentCommentIdx: string | undefined = undefined;
	// 컨텐트고유번호**
	contentIdx = '';
	// 댓글내용**
	commentBody: string | undefined = undefined;
	// 상위컨텐트댓글고유번호
	hirankContentCommentIdx: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

/**
 * 컨텐트 평가
 */
export class ContentEvalEntity extends ContentEntity {
	// 컨텐트평가대상고유번호
	contentEvalTargetIdx = '';
	// 컨텐트평가유형코드**
	contentEvalTypeCode: CONTENT_EVAL_TYPE_CODE = CONTENT_EVAL_TYPE_CODE.LIKEBAD;
	// 컨텐트평가회원고유번호**
	contentEvalUserIdx = '';
	// 컨텐트평가값**
	contentEvalValue = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

/**
 * 플레이 내역
 */
export class ContentPlayRecEntity extends ContentEntity {
	// 컨텐트플레이일련번호
	contentPlaySeq = 0;
	// 컨텐트고유번호**
	contentIdx = '';
	// 인증코드**
	certCode = '';
	// 만료일시**
	expireDt: Date | null = null;
	// 사용일시
	useDt: Date | null = null;
	// 사용여부
	useYn: STATE_YN = STATE_YN.N;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//저장 여부
	favContent: STATE_YN | null = null;
}

/**
 * 컨텐트 금액
 */
export class ContentPriceEntity extends ContentEntity {
	// 금액시작일자**
	priceStartDate: Date | null = null;
	// 금액종료일자**
	priceEndDate: Date | null = null;
	// 컨텐트고유번호**
	contentIdx = '';
	// 컨텐트금액**
	contentPrice = 0;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

/**
 * 컨텐트 태그 내역
 */
export class ContentTagRecEntity extends ContentEntity {
	// 컨텐트태그일련번호
	contentTagSeq = 0;
	// 컨텐트고유번호**
	contentIdx = '';
	// 태그명**
	tagName = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}
