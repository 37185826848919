<script lang="ts">
import ApocLink from '@/components/common/ApocLink.vue';
import AppConfig from '@/constants';
import { initStore } from '@/stores/store-manager';
import { ShareType, loadLocalData, saveLocalData } from '@/utils/common-util';
import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
	name: 'LangSelectModal',
	computed: {
		ShareType() {
			return ShareType;
		},
	},
	components: { ApocLink },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const storeManager = initStore();
		const lang = ref<string>('ko');

		const changeLang = (v: string) => {
			lang.value = v;
			saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, v);
			window.location.reload();
		};

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
			const loadLang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
			if (loadLang) {
				lang.value = loadLang;
			}
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			changeLang,
		};
	},
});
</script>
<template>
	<div class="contents-select-modal lang-select-modal">
		<div class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<apoc-link href="#" @click="changeLang('ko')">
				<span>
					{{ t('lang.ko') }}
				</span>
				<span>
					{{ t('KO') }}
				</span>
			</apoc-link>
			<apoc-link href="#" @click="changeLang('en')">
				<span>
					{{ t('lang.en') }}
				</span>
				<span>
					{{ t('EN') }}
				</span>
			</apoc-link>
		</div>
	</div>
</template>
