import type { AxiosInstance } from 'axios';
import { ResponseDto } from './dto/response.dto';
import { getApiHeader } from '../utils/apiClient';
import { FavEntityDto } from './dto/fav.dto';
import { FavEntity } from './model/fav.entity';
import { SearchFavListDto } from './dto/common.dto';

/**
 * 즐겨찾기 리스트 조회
 * @param client
 * @param param
 */
export function getFavContentList(client: AxiosInstance, param: SearchFavListDto): Promise<ResponseDto<FavEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<FavEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/fav/getFavContentList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<FavEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 즐겨찾기 리스트 조회
 * @param client
 * @param param
 */
export function getFavList(client: AxiosInstance, param: SearchFavListDto): Promise<ResponseDto<FavEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<FavEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/fav/getFavList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<FavEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 즐겨찾기 저장
 * @param client
 * @param param
 */
export function insertFav(client: AxiosInstance, param: FavEntity): Promise<ResponseDto<FavEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<FavEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/fav/insertFav', param)
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<FavEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 즐겨찾기 삭제
 * @param client
 * @param param
 */
export function deleteFav(client: AxiosInstance, param: FavEntity): Promise<ResponseDto<FavEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<FavEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/fav/deleteFav', param)
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<FavEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
