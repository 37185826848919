import { BasicListDto } from './request.dto';
import { STATE_YN, USER_TYPE } from '../../types';
// @ts-ignore
import { ChannelContentRecEntity, ChannelEntity, ChannelPartnerEntity } from '../model/channel.entity';

/**
 * 채널 검색용 DTO
 */
export class SearchChannelListDto extends BasicListDto {
	channelIdx = '';
	channelName = '';
	regrUserIdx = '';
}

/**
 * 채널 컨텐트 내역 검색용 DTO
 */
export class SearchChannelContentListDto extends BasicListDto {
	channelContentSeq = 0;
	channelIdx = '';
	contentIdx = '';
	regrUserIdx = '';
}

/**
 * 채널 참여자 검색용 DTO
 */
export class SearchChannelPartnerListDto extends BasicListDto {
	channelIdx = '';
	partnerUserIdx = '';
	regrUserIdx = '';
	delYn: STATE_YN = STATE_YN.N;
}

/**
 * 채널 정보 확장형 DTO
 */
export class ChannelEntityDto extends ChannelEntity {
	// 채널고유번호
	channelIdx = '';
	// 채널명**
	channelName = '';
	// 채널설명
	channelDesc = '';
	// 채널색상**
	channelColor = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
	//방장 권한 인원
	crownUserIdxList: string[] = [];
	//방장 권한 삭제 인원
	deleteCrownUserIdxList: string[] = [];
	//참여자 인원
	userIdxList: string[] = [];
	//삭제 인원
	deleteUserIdxList: string[] = [];
	//콘텐트 수
	contentCount = 0;
	//채널 파트너 수
	partnerCount = 0;
	//유저 닉네임
	userNicknm = '';
	//유저 프로필
	userProfileImg = '';
	//수정 권한이 있는가
	editable: boolean | null = null;
}

/**
 * 채널 콘텐트 내역 정보 확장형 DTO
 */
export class ChannelContentRecEntityDto extends ChannelContentRecEntity {
	channelName = '';
	channelDesc = '';
	contentName = '';
	playTime = '';
	summaryDesc = '';
	detailDesc = '';
	kidYn: STATE_YN | undefined = undefined;
	userName = '';
	userNickNm = '';
	userTypeCode: USER_TYPE | undefined = undefined;
	regrUserIdx = '';
	userProfileImg = '';
	contentMainImgInfo = '';
	contentPrvImgInfo = '';
	viewCount = 0;
	followingCount = 0;
	followerCount = 0;
	likeCount = 0;
	badCount = 0;
	playCount = 0;
	//반환 총갯수
	totalCount = 0;
	modDt: Date | null = null;
	regDt: Date | null = null;
}

/**
 * 채널 콘텐트 내역 정보 확장형 DTO
 */
export class ChannelPartnerEntityDto extends ChannelPartnerEntity {
	//반환 총갯수
	totalCount = 0;
	//유저 이름
	userName = '';
	//유저 영문명
	userEnName = '';
	//유저 닉네임
	userNicknm = '';
	//유저 아이디
	userId = '';
	//유저 프로필
	userProfileImg = '';
	// 참여자 회원 고유번호**
	partnerUserIdx = '';
	// 채널고유번호**
	channelIdx = '';
}
