<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { getApiClient } from '@/utils/apiClient';
import AppConfig from '@/constants';
import { useRoute } from 'vue-router';
import { copyContent } from '@/api/content';
import { ContentEntityDto } from '@/api/dto/content.dto';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';

export default defineComponent({
	name: 'ContentsCopyPopup',
	setup() {
		const { t } = useI18n();
		const countValue = ref<number | null>(null);
		const maximum = 10;
		const ready = ref<boolean>(false);
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const route = useRoute();
		const eventAccess = ref<boolean>(true);

		/**
		 * 콘텐츠 복사 갯수 체크
		 * @param e : Event
		 */
		const changeCountValue = (e: Event) => {
			const str = (e.target as HTMLInputElement).value;
			countValue.value = Number(str.replace(/[^0-9]/g, ''));
			if (countValue.value > maximum) countValue.value = maximum;
			if (countValue.value <= 0) countValue.value = null;
		};

		/**
		 * 엔터 눌렀을시 submit 되도록
		 * @param e : KeyboardEvent
		 */
		const onEnterEvent = (e: KeyboardEvent) => {
			if (e.code === 'Enter' && eventAccess.value) {
				eventAccess.value = false;
				doCopyContents();
			}
		};

		/**
		 * 콘텐츠 복사하기
		 */
		const doCopyContents = () => {
			if (countValue.value && countValue.value > 0 && countValue.value <= maximum) {
				if (storeManager.dataStore.contentsTargetIdx) {
					const contentIdx = storeManager.dataStore.contentsTargetIdx;
					const param = new ContentEntityDto();
					param.contentIdx = storeManager.dataStore.contentsTargetIdx as string;
					param.copyCount = countValue.value;
					copyContent(apiClient, param)
						.then(res => {
							if (res.resultCode === 0) {
								storeManager.dataStore.setContentsTargetIdx(undefined);
								storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
								eventAccess.value = true;
							}
						})
						.catch(() => {
							storeManager.dataStore.setContentsTargetIdx(undefined);
							storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
							eventAccess.value = true;
						});
				}
			}
		};

		watch(
			() => countValue.value,
			() => {
				ready.value = countValue.value !== null && countValue.value > 0 && countValue.value <= maximum;
			},
		);

		onMounted(() => {
			const textForm: HTMLInputElement = document.querySelector('.count') as HTMLInputElement;
			textForm.focus();
		});

		return {
			t,
			countValue,
			ready,
			changeCountValue,
			doCopyContents,
			onEnterEvent,
		};
	},
});
</script>
<template>
	<div class="contents-copy-popup">
		<div class="body">
			<h1 class="title">
				<div>
					{{ t('layout.my') }}
					<p>{{ t('page.Contents') }}</p>
					{{ t('layout.doCopy') }}
				</div>
				<!--				<hr />-->
			</h1>
			<span class="tip">
				<span>{{ t('layout.tip') }}</span>
				<p>
					{{ t('layout.contentsCopyTip') }}
				</p>
				<hr />
			</span>
			<div class="count-area">
				<input v-model="countValue" class="count" type="text" @input.stop="changeCountValue" @keydown="onEnterEvent" />
				<button :class="{ ready: ready }" @click="doCopyContents">{{ t('layout.doCopy') }}</button>
			</div>
		</div>
	</div>
</template>
