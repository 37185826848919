<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { setCookie } from 'typescript-cookie';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default defineComponent({
	name: 'Modal',
	props: {
		seq: {
			type: String,
			require: true,
		},
		title: {
			type: String,
			require: true,
		},
		onClosePopup: {
			type: Function as PropType<(payload: MouseEvent) => void>,
			require: true,
		},
	},
	setup(props) {
		const { t } = useI18n();

		const onClose24Hour = (e: MouseEvent) => {
			setCookie(`popup_flag_${props.seq}`, 'close', { expires: 1 });
			if (props.onClosePopup) {
				props.onClosePopup(e);
			}
		};

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			onClose24Hour,
		};
	},
});
</script>

<template>
	<div class="admin modal" @mousedown.self.stop.prevent="onClosePopup">
		<div class="admin-popup">
			<div class="popup-body viewer">
				<slot />
			</div>
			<div class="bottom-btn-wrapper">
				<button @click="onClose24Hour">
					{{ t('admin.CLOSE_24H') }}
				</button>
				<button @click="onClosePopup">
					{{ t('admin.close') }}
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
