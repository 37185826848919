import { BasicListDto } from './request.dto';
import { UserFollowerNoticeConfigEntity } from '@/api/model/notice.entity';

/**
 * 회원알림설정 검색용 DTO
 */
export class SearchUserNoticeConfigListDto extends BasicListDto {
	userIdx: string | undefined = undefined;
	userNoticeTypeCode: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
}

/**
 * 회원친구알림설정 검색용 DTO
 */
export class SearchUserFollwerNoticeConfigListDto extends BasicListDto {
	userFollowerSeq = 0;
	userFollowerNoticeTypeCode: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
	targetUserIdx: string | undefined = undefined;
}

/**
 * 알림큐 검색용 DTO
 */
export class SearchNoticeQueueListDto extends BasicListDto {
	noticeIdx: string | undefined = undefined;
	noticeTargetTypeCode: string | undefined = undefined;
	noticeTargetIdx: string | undefined = undefined;
	recipientUserIdx: string | undefined = undefined;
	adminBatchNoticeTypeCode: string | undefined = undefined;
	noticeSendTypeCode: string | undefined = undefined;
	noticeSendResvDt: Date | null = null;
	noticeSendStateCode: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
}

export class UserFollowerNoticeConfigEntityDto extends UserFollowerNoticeConfigEntity {
	targetUserIdx: string | undefined = undefined;
}
