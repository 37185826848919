import type { AxiosInstance } from 'axios';
import { getApiHeader } from '../utils/apiClient';
import { ResponseDto } from './dto/response.dto';
import {
	ChannelContentRecEntityDto,
	ChannelEntityDto,
	ChannelPartnerEntityDto,
	SearchChannelContentListDto,
	SearchChannelListDto,
	SearchChannelPartnerListDto,
} from './dto/channel.dto';
import { ChannelEntity } from './model/channel.entity';
import { ContentEntityDto } from './dto/content.dto';

/**
 * 채널 상세 조회
 * @param client
 * @param param
 */
export function saveChannel(client: AxiosInstance, param: ChannelEntityDto): Promise<ResponseDto<ChannelEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ChannelEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/channel/saveChannel', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ChannelEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 채널 상세 조회
 * @param client
 * @param param
 */
export function getChannelDetail(client: AxiosInstance, param: ChannelEntityDto): Promise<ResponseDto<ChannelEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ChannelEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/channel/getChannelDetail', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ChannelEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 채널 리스트 조회
 * @param client
 * @param param
 */
export function getChannelList(client: AxiosInstance, param: SearchChannelListDto): Promise<ResponseDto<ChannelEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ChannelEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/channel/getChannelList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ChannelEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 채널 콘텐트 내역 리스트 조회
 * @param client
 * @param param
 */
export function getChannelContentRecList(client: AxiosInstance, param: SearchChannelContentListDto): Promise<ResponseDto<ContentEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/channel/getChannelContentRecList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 채널 참여자 목록 조회
 * @param client
 * @param param
 */
export function getChannelPartnerList(client: AxiosInstance, param: SearchChannelPartnerListDto): Promise<ResponseDto<ChannelPartnerEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ChannelPartnerEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/channel/getChannelPartnerList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ChannelPartnerEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 채널 상세 조회
 * @param client
 * @param param
 */
export function deleteChannel(client: AxiosInstance, param: ChannelEntityDto): Promise<ResponseDto<ChannelEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ChannelEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/channel/deleteChannel', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ChannelEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
