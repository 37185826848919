import type { AxiosInstance } from 'axios';
import { getApiHeader, getApiFileHeader } from '@/utils/apiClient';
import { CategoryConnectRecEntityDto } from './dto/category.dto';
import { ResponseDto } from './dto/response.dto';
import {
	ContentCommentEntityDto,
	ContentEntityDto,
	ContentPlayRecEntityDto,
	RecommendContent,
	SearchContentCommentListDto,
	SearchContentListDto,
	SearchContentPlayRecListDto,
} from './dto/content.dto';
import { ContentCommentEntity, ContentEntity, ContentEvalEntity } from './model/content.entity';
import { CollaboRoomUserEntityDto, SearchCollaboRoomUserListDto } from '@/api/dto/collabo.dto';

/**
 * 콘텐트 리스트 조회
 * @param client
 * @param param
 */
export function getContentList(client: AxiosInstance, param: any): Promise<ResponseDto<ContentEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
/**
 * 콘텐트 열 번호 조회
 * @param client
 * @param param
 */
export function getContentIncludedRowNumber(client: AxiosInstance, param: any): Promise<ResponseDto<ContentEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentIncludedRowNumber', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
/**
 * 컨텐트 일자별 플레이 리스트 조회
 * @param client
 * @param param
 */
export function getContentPlayByDateList(client: AxiosInstance, param: SearchContentPlayRecListDto): Promise<ResponseDto<ContentPlayRecEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentPlayRecEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentPlayByDateList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentPlayRecEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 상세 조회
 * @param client
 * @param param
 */
export function getContentDetail(client: AxiosInstance, param: ContentEntityDto): Promise<ResponseDto<ContentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentDetail', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<ContentEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 메인 콘텐트 상세 조회
 * @param client
 * @param param
 */
export function getRecommendContentList(client: AxiosInstance, param: ContentEntityDto): Promise<ResponseDto<RecommendContent[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<RecommendContent[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getRecommendContentList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<RecommendContent[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 카테고리에 연결된 콘텐트 리스트 조회
 * @param client
 * @param param
 */
export function getContentListIncludedCategory(
	client: AxiosInstance,
	param?: SearchContentListDto,
): Promise<ResponseDto<CategoryConnectRecEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<CategoryConnectRecEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentListIncludedCategory', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<CategoryConnectRecEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 조회수 증가
 * @param client
 * @param param
 */
export function updateContentReadCount(client: AxiosInstance, param: ContentEntityDto): Promise<ResponseDto<ContentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/updateContentReadCount', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 복사
 * @param client
 * @param param
 */
export function copyContent(client: AxiosInstance, param: ContentEntityDto): Promise<ResponseDto<ContentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/copyContent', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 플레이 내역 저장
 * @param client
 * @param param
 */
export function insertContentPlayRec(client: AxiosInstance, param: ContentPlayRecEntityDto): Promise<ResponseDto<ContentPlayRecEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentPlayRecEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/insertContentPlayRec', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentPlayRecEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 플레이 내역 상세 조회
 * @param client
 * @param param
 */
export function getContentPlayRecDetail(client: AxiosInstance, param: ContentPlayRecEntityDto): Promise<ResponseDto<ContentPlayRecEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentPlayRecEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/getContentPlayRecDetail', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentPlayRecEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 삭제
 * @param client
 * @param param
 */
export function deleteContent(client: AxiosInstance, param: ContentEntity): Promise<ResponseDto<ContentEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/deleteContent', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 삭제
 * @param client
 * @param param
 */
export function deleteContentList(client: AxiosInstance, param: ContentEntity): Promise<ResponseDto<ContentEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/deleteContentList', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 평가 추가
 * @param client
 * @param param
 */
export function insertContentEval(client: AxiosInstance, param: ContentEvalEntity): Promise<ResponseDto<ContentEvalEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEvalEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/insertContentEval', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEvalEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 평가 삭제
 * @param client
 * @param param
 */
export function deleteContentEval(client: AxiosInstance, param: ContentEvalEntity): Promise<ResponseDto<ContentEvalEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEvalEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/deleteContentEval', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEvalEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 컨텐트 평가 추가
 * @param client
 * @param param
 */
export function updateContentEval(client: AxiosInstance, param: ContentEvalEntity): Promise<ResponseDto<ContentEvalEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEvalEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/updateContentEval', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEvalEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 저장
 * @param client
 * @param param
 */
export function saveContent(client: AxiosInstance, param: any): Promise<ResponseDto<ContentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/saveContent', param, getApiFileHeader())
			.then(res => {
				fnResolve(new ResponseDto<ContentEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 코멘트 저장
 * @param client
 * @param param
 */
export function insertContentComment(client: AxiosInstance, param: ContentCommentEntity): Promise<ResponseDto<ContentCommentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentCommentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/insertContentComment', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentCommentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 코멘트 삭제
 * @param client
 * @param param
 */
export function updateContentComment(client: AxiosInstance, param: ContentCommentEntity): Promise<ResponseDto<ContentCommentEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentCommentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/updateContentComment', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentCommentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 코멘트 삭제
 * @param client
 * @param param
 */
export function deleteContentComment(client: AxiosInstance, param: ContentCommentEntity): Promise<ResponseDto<ContentCommentEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentCommentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/deleteContentComment', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentCommentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 코멘트 리스트 조회
 * @param client
 * @param param
 */
export function getContentCommentList(client: AxiosInstance, param: SearchContentCommentListDto): Promise<ResponseDto<ContentCommentEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentCommentEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentCommentList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentCommentEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐트 간단 정보 업데이트
 * @param client
 * @param param
 */
export function updateSimplyInfoContent(client: AxiosInstance, param: ContentEntityDto): Promise<ResponseDto<ContentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/content/updateSimplyInfoContent', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ContentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
/**
 * 콘텐트 상세 조회
 * @param client
 * @param param
 */
export function getCollaboAndChannelUserList(
	client: AxiosInstance,
	param: SearchCollaboRoomUserListDto,
): Promise<ResponseDto<CollaboRoomUserEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<CollaboRoomUserEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/collabo/getCollaboAndChannelUserList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<CollaboRoomUserEntityDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 콘텐츠 플레이 내역 조회
 * @param client
 * @param param
 */
export function getContentPlayRecList(client: AxiosInstance, param: SearchContentPlayRecListDto): Promise<ResponseDto<ContentPlayRecEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ContentPlayRecEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/content/getContentPlayRecList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<ContentPlayRecEntityDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
