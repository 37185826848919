import type { AxiosInstance } from 'axios';
import { getApiHeader } from '../utils/apiClient';

export interface PopupInterface {
	seq: string; // 일련번호
	title: string; // 제목
	body: string; // 내용
	desc?: string; // 설명
	popupCategory: string; // 팝업카테고리
	target: string; // 대상
	startDt: string; // 시작일시
	endDt: string; // 종료일시
	openYn: string; // 공개여부
	regUserIdx: string; // 등록자idx
	regDt: string; // 등록일시
	modrUserIdx?: string; // 수정자idx
	modDt?: string; // 수정일시
	delYn: string; // 삭제여부
}

export interface PopupUpsertInterface {
	seq?: string; // 일련번호
	title?: string; // 제목
	body?: string; // 내용
	desc?: string; // 설명
	popupCategory?: string; // 팝업카테고리
	target?: string; // 대상
	startDt?: string; // 시작일시
	endDt?: string; // 종료일시
	openYn?: string; // 공개여부
	delYn?: string; // 삭제여부
}

export interface PopupSearchInterface {
	popupCategory: string;
}

/**
 * 팝업 매니저 목록 조회
 * @param client
 * @param param
 */
export function getPopupList(client: AxiosInstance, param: PopupSearchInterface): Promise<PopupInterface[]> {
	const promiseFn = (fnResolve: (value: PopupInterface[]) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/popup/getPopupList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(res.data.data);
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

export function upsertPopup(client: AxiosInstance, param: PopupUpsertInterface): Promise<PopupInterface> {
	const promiseFn = (fnResolve: (value: PopupInterface) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/popup/insertUpdatePopup', param)
			.then(res => {
				if (res.data.resultCode !== 0) {
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(res.data.data);
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
