<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import ApocButton from '@/components/common/ApocButton.vue';
import { DEFECT_PROCESS_STATE_CODE, UserInfoInterface } from '@/types';
import { loadLocalData } from '@/utils/common-util';
import AppConfig from '@/constants';
import { getApiClient } from '@/utils/apiClient';
import { useI18n } from 'vue-i18n';
import { insertDefect } from '@/api/defect';
import { DefectEntity } from '@/api/model/defect.entity';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { HeaderSelectOption } from '@/components/common/apocSelect';
import DynamicOptionSelect from '@/components/common/DynamicOptionSelect.vue';

export interface ReportOptionInterface {
	value: string | number;
	label: string;
	inputText: boolean;
}

export default defineComponent({
	name: 'ContentReportPopup',
	components: { DynamicOptionSelect, ApocButton },
	setup() {
		const storeManager = initStore();
		const { t } = useI18n();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const userInfo = ref<UserInfoInterface | null>(null);
		const activeType = ref<number>(0);
		const inputText = ref<string>('');
		const reportList: HeaderSelectOption[] = [
			{
				label: t('popup.reportCategory.0'),
				value: 'n',
			},
			{
				label: t('popup.reportCategory.9'),
				value: 'n',
			},
		];
		const saveData = ref<HeaderSelectOption | undefined>(undefined);
		const selectOptionParentElement = ref<HTMLElement | undefined>(undefined);

		const closePopup = (): void => {
			storeManager.dataStore.setPopupReportTargetId('');
			storeManager.dataStore.setPopupReportTargetType('');
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const onClickReport = () => {
			if (!saveData.value) return;
			const param = {
				userIdx: userInfo.value?.userIdx,
				defectTargetIdx: storeManager.dataStore.popupReportTargetId,
				defectTitle: saveData.value.label,
				defectDesc: inputText.value,
				defectType: storeManager.dataStore.popupReportTargetType,
			};
			if (activeType.value === 0) {
				alert(t('msg.SELECT_REPORT_CATEGORY'));
			} else {
				insertDefectReport();
			}
		};

		const insertDefectReport = () => {
			if (!saveData.value) return;
			const param = new DefectEntity();
			param.defectTargetIdx = storeManager.dataStore.popupReportTargetId;
			param.defectTitle = saveData.value.label;
			param.defectBody = inputText.value;
			param.defectTypeCode = storeManager.dataStore.popupReportTargetType;
			param.defectProcessStateCode = DEFECT_PROCESS_STATE_CODE.SUBMIT;
			insertDefect(apiClient, param).then(res => {
				if (res.resultCode !== 0) {
					window.alert(t('msg.' + res.resultMsg));
				} else {
					alert(t('msg.RESULT_REPORT_SUCCESS'));
					closePopup();
				}
			});
		};

		const isShowOptions = ref<boolean>(false);

		const clickOptionBox = () => {
			isShowOptions.value = !isShowOptions.value;
		};

		const closeDropdownArea = () => {
			isShowOptions.value = false;
		};

		const setActiveType = (index: number) => {
			activeType.value = index;
			isShowOptions.value = false;
		};
		const selectReport = (v: HeaderSelectOption) => {
			saveData.value = v;
			closeDropdownArea();
		};

		onMounted(() => {
			const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			if (user) {
				userInfo.value = JSON.parse(user);
			}
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			activeType,
			inputText,
			isShowOptions,
			reportList,
			selectOptionParentElement,
			saveData,
			setActiveType,
			clickOptionBox,
			closeDropdownArea,
			closePopup,
			onClickReport,
			selectReport,
		};
	},
});
</script>

<template>
	<div class="report modal channel-popup-bg" @mousedown.self.stop.prevent="closePopup">
		<div class="report-popup">
			<div id="content-popup-options"></div>
			<i class="fa fa-light fa-xmark close-btn" @click="closePopup"></i>
			<div ref="selectOptionParentElement" class="popup-content-area">
				<div class="popup-header">
					<span>{{ t('popup.reportTitle') }}</span>
				</div>
				<div class="popup-body">
					<div class="title">{{ t('popup.reportReason') }}<strong>*</strong></div>
					<!-- pc/tab의 경우 -->
					<div class="popup-select-wrapper non-mobile">
						<dynamic-option-select
							v-if="reportList"
							:option-list="reportList"
							:placeholder="reportList[0].label"
							:select-option-parent="selectOptionParentElement as HTMLElement"
							:except-placeholder-from-list="false"
							:enable-scroll-event="false"
							:enable-resize-event="false"
							:on-change="selectReport" />
					</div>
					<!-- mobile의 경우 -->
					<div v-click-away="closeDropdownArea" class="select-box mobile">
						<div :class="{ active: isShowOptions }" class="active-type" @click="clickOptionBox">
							{{ saveData ? saveData.label : reportList[0].label }}
							<i class="fa fa-light fa-angle-down"></i>
						</div>
						<transition name="dropdown">
							<ul v-if="isShowOptions" class="dropdown-area">
								<li v-for="item in reportList" :key="item.label">
									<span @click="selectReport(item)">{{ item.label }}</span>
								</li>
							</ul>
						</transition>
					</div>
					<textarea v-if="saveData && saveData.value === 'y'" v-model="inputText" :placeholder="t('popup.inputReportContent')" />
					<p>{{ t('popup.reportRule1') }}</p>
					<p>{{ t('popup.reportRule2') }}</p>
				</div>
				<div class="button-wrapper">
					<apoc-button class="popup-btn save" @click="onClickReport">{{ t('popup.reportTitle') }}</apoc-button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
