import { BasicListDto } from './request.dto';
import { BOARD_EVAL_LIKE_CODE, BOARD_TYPE_CODE, STATE_YN, USER_TYPE } from '../../types';
import { BoardCommentEntity, BoardEntity } from '@/api/model/board.entity';

/**
 * 게시판 검색용
 */
export class SearchBoardDto extends BasicListDto {
	boardTypeCode: BOARD_TYPE_CODE | undefined = undefined;
	boardIdx = '';
	title = '';
	body = '';
	regrUserIdx = '';
}

/**
 * 게시판 댓글 검색용
 */
export class SearchBoardCommentDto extends BasicListDto {
	boardTypeCode = '';
	boardCommentIdx = '';
	boardIdx = '';
	commentBody = '';
	hirankBoardCommentIdx = '';
	regrUserIdx = '';
}

/**
 * 게시판 평가 검색용
 */
export class SearchBoardEvalDto extends BasicListDto {
	boardTypeCode = '';
	boardEvalTargetIdx = '';
	boardEvalTypeCode = '';
	boardEvalUserIdx = '';
	regrUserIdx = '';
}

/**
 * 게시판 확장형 dto
 */
export class BoardEntityDto extends BoardEntity {
	//반환 총갯수
	totalCount = 0;
	//게시판 썸네일 이미지
	boardMainImg = '';
	// 댓글 총갯수
	commentCount = 0;
	// 읽음 총갯수
	readCount = 0;
	// 좋아요 갯수
	likeCount = 0;
	// 내가 좋아요 했는지
	likeBoard: BOARD_EVAL_LIKE_CODE | undefined = undefined;
	// 유저 고유번호
	userIdx = '';
	// 유저 이름
	userName = '';
	// 유저 영문명
	userEnName = '';
	// 유저 타입 코드
	userTypeCode: USER_TYPE = USER_TYPE.USER;
	//유저 프로필
	userProfileImg: string | undefined = undefined;
	// 유저 아이디
	userId = '';
	// 유저 닉네임
	userNicknm = '';
	// 삭제 파일 여부 => true 일경우 썸네일 삭제
	deleteFileState = false;
	// html tag를 제외한 순수 text
	textBody = '';
}

export class BoardCommentEntityDto extends BoardCommentEntity {
	// 내가 좋아요 했는지
	likeBoard: BOARD_EVAL_LIKE_CODE | undefined = undefined;
	// 유저 닉네임
	userNicknm: string | undefined = undefined;
	// 유저 프로필
	userProfileImg: string | undefined = undefined;
	//코멘트를 좋아요 했는지
	likeComment: BOARD_EVAL_LIKE_CODE | undefined = undefined;
	// 좋아요 갯수
	likeCount = 0;
}

/**
 * 게시판 삭제용
 */
export class DeleteBoardDto extends BoardEntity {
	boardIdx = '';
}
