<script lang="ts">
import { initStore } from "@/stores/store-manager";
import type { PropType } from "vue";
import { computed, defineComponent, ref, watch } from "vue";
import { INPUT_MAXLENGTH_TYPE } from "@/types/index";

export default defineComponent({
	name: "ApocInput",
	props: {
		placeholder: {
			type: String as PropType<string>,
			default: "",
		},
		type: {
			type: String as PropType<string>,
			default: "text",
		},
		onEnter: {
			type: Function as PropType<(result: string) => void>,
			required: false,
			default: () => ({}),
		},
		modelValue: {
			type: String as PropType<string>,
		},
		handdleKeyUp: {
			type: Function as PropType<(v: string) => string>,
			required: false,
		},
		useKeyword: {
			type: Boolean as PropType<boolean>,
			required: false,
		},
		readOnly: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		autofocus: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		length: {
			type: Number,
			required: false,
			default: 200,
		},
		numberOnly: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
	},
	setup(props, { emit }) {
		const storeManager = initStore();
		const inputValue = ref(props.modelValue || "");
		const compModelValue = computed(() => props.modelValue || "");

		const lengthValue = ref(props.length);

		const stopModelValue = watch(compModelValue, () => {
			inputValue.value = props.modelValue || "";
		});
		const handleKeyup = (e: KeyboardEvent): void => {
			if (e.code === "Enter") {
				if (props.onEnter) props.onEnter(inputValue.value);
			}
			const value = e.target as HTMLInputElement;
			if (props.handdleKeyUp) {
				value.value = props.handdleKeyUp(value.value as string);
			} else if (props.type === "profile") {
				value.value = value.value.replace(
					/[^A-Za-z0-9가-힣\u3131-\u318E]/g,
					"",
				);
				lengthValue.value = INPUT_MAXLENGTH_TYPE.PROFILE;
			}
			emit("update:model-value", value.value);
		};
		const handleInput = (e: Event): void => {
			if (props.numberOnly) {
				const regex = /^[0-9]*(\.[0-9]+)?$/; //숫자(정수 + 소수)정규식
				const inputData = (e as InputEvent).data; //입력한 값

				//숫자가 아닌 값이 입력된 경우 (null 값이 아닌 조건도 넣어줘야 backspace로 지운 경우 걸러집니다)
				if (inputData && inputData !== '.') {
					if (!regex.test(inputData)) (e.target as HTMLInputElement).value = '';
				}
			}
			if (props.type === 'file') {
				const v = (e.target as HTMLInputElement).files;
				emit(`update:modelValue`, v);
			} else {
				const v = (e.target as HTMLInputElement).value.normalize('NFC');
				emit(`update:modelValue`, v);
			}
		};
		const handleBlur = (e: Event): void => {
			emit(`blur`, (e.target as HTMLInputElement).value);
		};
		//모바일 엔터 버튼 감지
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Enter") {
				e.preventDefault(); // 기본 동작 방지
				// window.alert(compModelValue.value);
				emit("enter", compModelValue.value);
			}
		};

		// const autoFocus = () => {
		// 	if (props.autofocus) {
		// 		const bannerInput = document.querySelector('.content-banner input') as HTMLInputElement; // 메인 페이지 배너에 포함된 서치바
		// 		const headerInput = document.querySelector('.header-search input') as HTMLInputElement; // 헤더에 포함된 서치바

		// 		if (storeManager.stateStore.headerVisible) {
		// 			const scrollY = window.scrollY;
		// 			bannerInput.focus();
		// 			window.scrollTo(0, scrollY);
		// 		} else {
		// 			// mobile
		// 			if (window.innerWidth >= 767) headerInput.focus();
		// 		}
		// 	}
		// };

		// watch(
		// 	() => storeManager.stateStore.headerVisible,
		// 	() => {
		// 		autoFocus();
		// 	},
		// );

		return {
			inputValue,
			storeManager,
			lengthValue,
			stopModelValue,
			handleBlur,
			handleKeyup,
			handleInput,
			handleKeyDown,
		};
	},
	unmounted() {
		this.stopModelValue();
	},
});
</script>

<template>
	<div class="apoc-input full-width">
		<input
			v-model="inputValue"
			:readonly="readOnly"
			:placeholder="placeholder"
			:type="type === 'profile' ? 'text' : type"
			:autocomplete="type === 'password' || type === 'email' ? 'on' : 'off'"
			:maxlength="lengthValue"
			@keydown="handleKeyDown"
			@blur="handleBlur"
			@input="handleInput"
			@keyup="handleKeyup" />
	</div>
</template>

<style scoped></style>
