<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import ApocRenewalPopup from './ApocRenewalPopup.vue';
import ApocPromoPopup from './ApocPromoPopup.vue';
import { getCookie, setCookie } from 'typescript-cookie';
import { initStore } from '@/stores/store-manager';
import { POPUP_MODE } from '@/stores/state-store';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';

export default defineComponent({
	name: 'ApocPopups',
	components: { ApocRenewalPopup, ApocPromoPopup },
	setup() {
		const storeManager = initStore();
		const isCloseRenewal = ref<boolean>(false);
		const isClosePromo = ref<boolean>(false);
		// 하루동안 보지 않기 클릭
		const onClickClose24h = (name: string) => {
			if (name === 'renewal') {
				setCookie(`renewal_popup_close_flag`, 'close', { expires: 1 });
				closePopup(name);
			}
			if (name === 'promo') {
				setCookie(`promo_popup_close_flag`, 'close', { expires: 1 });
				closePopup(name);
			}
		};

		// 확인 클릭
		const closePopup = (name: string) => {
			if (name === 'renewal') isCloseRenewal.value = true;
			if (name === 'promo') isClosePromo.value = true;

			if (isCloseRenewal.value && isClosePromo.value) {
				storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			}
		};

		// 팝업 제외 영역 클릭 시 모두 닫게 하기
		const clickPopupBg = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const clickPopup = (e: Event) => {
			e.stopPropagation(); // 팝업 클릭 시, 닫히기 막기
		};

		onMounted(() => {
			const renewalFlag = getCookie(`renewal_popup_close_flag`);
			const promoFlag = getCookie(`promo_popup_close_flag`);

			if (renewalFlag === 'close') {
				closePopup('renewal');
			}

			if (promoFlag === 'close') {
				closePopup('promo');
			}

			if (renewalFlag !== 'close' || promoFlag !== 'close') lockBackgroundScroll();
		});

		onBeforeUnmount(() => {
			unLockBackgroundScroll();
		});

		return {
			isCloseRenewal,
			isClosePromo,
			onClickClose24h,
			closePopup,
			clickPopupBg,
			clickPopup,
		};
	},
});
</script>
<template>
	<div class="apoc-popups" :class="{ 'only-one': isCloseRenewal || isClosePromo }" @click="clickPopupBg">
		<Transition name="wfade-mslide">
			<apoc-renewal-popup v-show="!isCloseRenewal" @close="closePopup('renewal')" @close-day="onClickClose24h('renewal')" @click="clickPopup" />
		</Transition>
		<Transition name="wfade-mslide">
			<apoc-promo-popup v-show="!isClosePromo" @close="closePopup('promo')" @close-day="onClickClose24h('promo')" @click="clickPopup" />
		</Transition>
	</div>
</template>
