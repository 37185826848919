import {
	BOARD_EVAL_LIKE_CODE,
	BOARD_EVAL_LIKEBAD_CODE,
	BOARD_EVAL_TYPE_CODE,
	BOARD_TYPE_CODE,
	STATE_YN,
} from '../../types';

// 게시판 정보
export class BoardEntity {
	// 게시판고유번호**
	boardIdx = '';
	// 게시판유형코드**
	boardTypeCode: BOARD_TYPE_CODE = BOARD_TYPE_CODE.NOTICE;
	// 제목**
	title = '';
	// 내용**
	body = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

// 게시판 댓글
export class BoardCommentEntity extends BoardEntity {
	// 게시판댓글고유번호**
	boardCommentIdx: string | undefined = undefined;
	// 게시판유형코드**
	boardTypeCode: BOARD_TYPE_CODE = BOARD_TYPE_CODE.NOTICE;
	// 게시판고유번호**
	boardIdx = '';
	// 댓글내용**
	commentBody = '';
	// 상위게시판댓글고유번호
	hirankBoardCommentIdx: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
}

// 게시판 평가
export class BoardEvalEntity extends BoardEntity {
	// 게시판유형코드**
	boardTypeCode: BOARD_TYPE_CODE = BOARD_TYPE_CODE.NOTICE;
	// 게시판평가대상고유번호**
	boardEvalTargetIdx = '';
	// 게시판평가유형코드**
	boardEvalTypeCode: BOARD_EVAL_TYPE_CODE = BOARD_EVAL_TYPE_CODE.LIKE;
	// 게시판평가회원고유번호**
	boardEvalUserIdx = '';
	// 게시판평가값**
	boardEvalValue: BOARD_EVAL_LIKE_CODE = BOARD_EVAL_LIKE_CODE.LIKE;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
}
