<script lang="ts">
import { getShortUrl } from '@/api/common';
import { BoardCommentEntityDto } from '@/api/dto/board.dto';
import { SearchShortUrlListDto } from '@/api/dto/common.dto';
import { UserInfoEntityDto } from '@/api/dto/user.dto';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { computed, defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { deleteBoardComment } from '@/api/board';
import { deleteFav, insertFav } from '@/api/fav';
import { ContentCommentEntity, ContentEntity } from '@/api/model/content.entity';
import { FavEntity } from '@/api/model/fav.entity';
import AppConfig from '@/constants';
import { generateDetailShareLink, loadLocalData } from '@/utils/common-util';
import { DEFECT_TYPE_CODE, FAV_TYPE_CODE, SHORT_TYPE_CODE, STATE_YN } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { ShareType, generateShortShareLink } from '@/utils/common-util';
import ApocLink from '@/components/common/ApocLink.vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { ContentEntityDto } from '@/api/dto/content.dto';
import { deleteContent, deleteContentComment, getContentDetail } from '@/api/content';
import { goAuthor, goAuthorContextInterface } from '@/utils/apoc-utils';
import moment from 'moment/moment';

export default defineComponent({
	name: 'ContentsOptionModal',
	components: { ApocLink, ApocImageSet },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const router = useRouter();
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const loginUser = JSON.parse(loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER) as string);
		const compSavedCrackList = computed(() => storeManager.dataStore.savedCrackIdxList);
		const compCrackIdx = computed(() => storeManager.dataStore.popupContentId);
		const compCrackContent = computed(() => storeManager.dataStore.contentsTargetInfo);
		const isFav = computed(() => storeManager.dataStore.isFav);
		const editAble = ref(false); // 콘텐츠 주인이거나, 채널, 콘텐츠에 협업되어 있으면 수정권한 있음

		const isAlreadySavedCrack = (): boolean => {
			return compSavedCrackList.value.includes(compCrackIdx.value);
		};

		const onClickClose = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const onClickSave = () => {
			const param = new FavEntity();
			param.targetIdx = compCrackIdx.value as string;
			param.favTypeCode = FAV_TYPE_CODE.CONTENT;
			if (isFav.value === STATE_YN.Y) {
				//이미 저장한 콘텐츠일 경우

				deleteFav(apiClient, param).then(res => {
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						dispatchEvent(new CustomEvent(AppConfig.KEYS.EVENTS.DO_FAV_SAVE));
						alert(t('msg.RESULT_CANCEL_SAVE'));
						closePopup();
					}
				});
			} else {
				//저장하지 않은 콘텐츠일 경우

				insertFav(apiClient, param).then(res => {
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						dispatchEvent(new CustomEvent(AppConfig.KEYS.EVENTS.DO_FAV_SAVE));
						alert(t('msg.RESULT_SAVE'));
						closePopup();
					}
				});
			}
		};

		const closePopup = (): void => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			storeManager.dataStore.setPopupContentId('');
			storeManager.dataStore.setContentsTargetInfo(undefined);
		};

		const onClickShare = async () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.CONTENT_SHARE_POPUP);
		};

		const onClickDelete = () => {
			if (window.confirm(t('msg.CONFIRM_DELETE_CONTENTS'))) {
				const param = new ContentEntity();
				param.contentIdx = storeManager.dataStore.popupContentId as string;
				deleteContent(apiClient, param)
					.then(res => {
						if (res.resultCode === 0) {
							window.alert(t(`msg.RESULT_DELETE`));
							window.location.reload();
						} else {
						}
					})
					.catch(err => {
						window.alert(t(`${err}`));
					});
			}
		};

		const onClickCommentDelete = () => {
			if (window.confirm(t('msg.ALERT_CHECK_DELETE_COMMENT'))) {
				if (storeManager.dataStore.popupContentsOptionType === 'myComment') {
					const param = new ContentCommentEntity();
					param.contentCommentIdx = storeManager.dataStore.popupContentId;
					deleteContentComment(apiClient, param).then(res => {
						if (res.resultCode !== 0) {
							window.alert(t('msg.' + res.resultMsg));
						} else {
							storeManager.stateStore.setIsDoSearchComment(true);
						}
					});
				} else {
					const param = new BoardCommentEntityDto();
					param.boardCommentIdx = storeManager.dataStore.popupContentId as string;
					deleteBoardComment(apiClient, param).then(res => {
						if (res.resultCode !== 0) {
							window.alert(t('msg.' + res.resultMsg));
						} else {
							storeManager.stateStore.setIsDoSearchComment(true);
						}
					});
				}
			}
		};

		const handleContentModify = () => {
			storeManager.dataStore.setModifyContentIdx(storeManager.dataStore.popupContentId);
			storeManager.stateStore.setOpenAuthor(true);
			const contentsIdx = storeManager.dataStore.popupContentId;
			const param = {
				authorUrl: `${AppConfig.AUTHOR_HOST}author?type=&idx=${contentsIdx}&lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}`,
				ctx: JSON.stringify({
					id: contentsIdx,
					lang: loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG),
				}),
				target: `content_${contentsIdx}`,
			};
			goAuthor(param, storeManager);
		};

		// 신고하기
		const onClickReport = () => {
			storeManager.dataStore.setPopupReportTargetId(storeManager.dataStore.popupContentId as string);
			storeManager.dataStore.setPopupReportTargetType(DEFECT_TYPE_CODE.BOARD_COMMENT);
			storeManager.stateStore.setPopupMode(POPUP_MODE.REPORT_POPUP);
		};

		// 출처 조회
		const onClickCopyright = () => {
			const scrollWidth = document.documentElement.clientWidth + 'px';
			document.body.style.width = scrollWidth;
			document.getElementsByTagName('header')[0].style.width = scrollWidth;
			storeManager.dataStore.setCopyrightContentIdx(storeManager.dataStore.popupContentId);
			storeManager.stateStore.setPopupMode(POPUP_MODE.COPYRIGHT_POPUP);
		};

		//TODO 내 콘텐츠 관리에서 해당 콘텐츠의 위치로 노출되도록 해야함
		//콘텐츠 관리 클릭
		const onClickMyContents = () => {
			closePopup();
			router.push('/my-contents');
		};

		/**
		 * 로그인 유저인지 확인 후 수정 권한 부여
		 */
		const verifyLogin = async () => {
			// 로그인 데이터가 있는 경우
			if (loginUser !== null) {
				editAble.value = compCrackContent.value?.userIdx === loginUser.userIdx;
			} else {
				// 로그인 데이터가 없는 경우
				editAble.value = false;
			}
		};

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
			verifyLogin();
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			isFav,
			storeManager,
			ShareType,
			editAble,
			onClickClose,
			onClickReport,
			onClickCopyright,
			handleContentModify,
			onClickDelete,
			onClickCommentDelete,
			isAlreadySavedCrack,
			onClickSave,
			onClickShare,
			onClickMyContents,
			// clickAwayHandler: () => {
			// 	closePopup();
			// },
		};
	},
	computed: {
		STATE_YN() {
			return STATE_YN;
		},
	},
});
</script>

<template>
	<div :class="storeManager.dataStore.popupContentsOptionType === 'myComment' ? 'replace-z' : ''" class="contents-select-modal">
		<!--				<div class="bg" @click="onClickClose" />-->
		<div v-if="storeManager.dataStore.popupContentsOptionType === 'my'" class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<apoc-link class="change-height-top" href="#" @click="handleContentModify"><i class="fa fa-light fa-pen" />{{ t('layout.doEdit') }} </apoc-link>
			<apoc-link class="change-height-bottom" href="#" @click="onClickDelete">
				<i class="fa fa-light fa-circle-minus" />{{ t('layout.doDelete') }}
			</apoc-link>
		</div>
		<div
			v-else-if="
				storeManager.dataStore.popupContentsOptionType === 'myComment' || storeManager.dataStore.popupContentsOptionType === 'myBoardComment'
			"
			class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<apoc-link href="#" @click="onClickCommentDelete" @touchstart="onClickCommentDelete">
				<i class="fa fa-light fa-circle-minus" />{{ t('layout.doDelete') }}
			</apoc-link>
		</div>
		<div v-else class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<!-- <apoc-link v-if="isFav === STATE_YN.Y" class="change-height-top" href="#" @click="onClickSave"
				><i class="fa fa-light fa-xmark"></i>{{ t('common.cancelSave') }}
			</apoc-link>
			<apoc-link v-else class="change-height-top" href="#" @click="onClickSave"><i class="fa fa-light fa-plus" />{{ t('common.doSave') }} </apoc-link>
			<apoc-link href="#" @click="onClickShare(ShareType.COPY_LINK)"
				><apoc-image-set src="/assets/images/common/icons/mypage/icon_share.svg" />{{ t('common.shareContents') }}
			</apoc-link> -->
			<apoc-link href="#" @click="onClickShare()">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_share-navy.svg" />{{ t('layout.myContents.shareContents') }}
			</apoc-link>
			<apoc-link href="#" @click="onClickReport">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_emoji_flags.svg" />{{ t('common.report') }} </apoc-link
			><apoc-link href="#" @click="onClickCopyright">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_info.svg" />{{ t('common.source') }}
			</apoc-link>
			<div v-if="editAble" class="border-line sm-margin"></div>
			<apoc-link v-if="editAble" class="manage-content" href="#" @click="onClickMyContents">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_draw-polygon2.svg" />{{ t('common.manageContent') }}
			</apoc-link>
		</div>
	</div>
</template>

<style scoped></style>
