import { ILoadingInterface } from '@/types/index';

const createEmitter = <T extends Record<string, (...args: any[]) => void>>() => {
	const eventMap = {} as Record<keyof T, Set<(...args: any[]) => void>>;

	return {
		emit: <K extends keyof T>(event: K, ...args: Parameters<T[K]>) => {
			(eventMap[event] ?? []).forEach(cb => cb(...args));
		},

		on: <K extends keyof T>(event: K, callback: T[K]) => {
			if (!eventMap[event]) {
				eventMap[event] = new Set();
			}

			eventMap[event].add(callback);
		},

		off: <K extends keyof T>(event: K, callback?: T[K]) => {
			if (!eventMap[event]) {
				return;
			}

			if (callback) {
				eventMap[event].delete(callback);
			} else {
				eventMap[event].clear();
			}
		},
	};
};

/**
 * 전체 공통으로 사용되는 EVENT
 * @author @backbone
 * @date 2023.07.13
 */
const coreEmitter = createEmitter<{
	//로딩타입
	LOADING_STATE: (v: ILoadingInterface) => void;
}>();

export const getEmitter = () => {
	return coreEmitter;
};
