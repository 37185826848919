import { AxiosInstance } from 'axios';
import { ResponseDto } from '@/api/dto/response.dto';
import { SubscriptionEntity } from '@/api/model/subscription.entity';
import { SubscriptionDto } from '@/api/dto/subscription.dto';
import { getApiHeader } from '@/utils/apiClient';

/**
 * 내 구독체크 및 특장점 조회
 * @param client
 * @param param
 */
export function getSubscription(client: AxiosInstance, param: SubscriptionEntity): Promise<ResponseDto<SubscriptionDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<SubscriptionDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/subscription/getSubscription', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<SubscriptionDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 내 구독체크 및 특장점 조회
 * @param client
 * @param param
 */
export function getSubscriptionFeatureValue(
	client: AxiosInstance,
	param: { userIdx: string; featureCode: string },
): Promise<ResponseDto<string | null>> {
	const promiseFn = (fnResolve: (value: ResponseDto<string | null>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/subscription/getSubscriptionFeatureValue', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<string | null>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
