<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
	name: 'CopyToastPopup',
	setup() {
		const { t } = useI18n();
		return {
			t,
		};
	},
});
</script>

<template>
	<div class="toast-wrapper">
		<div class="toast">{{ t('common.clipboard') }}</div>
	</div>
</template>

<style scoped></style>
