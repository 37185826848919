import { STATE_YN, USER_PERMISSION_TYPE_CODE } from '../../types';

// 채널 정보
export class ChannelEntity {
	// 채널고유번호
	channelIdx = '';
	// 채널명**
	channelName: string | undefined = undefined;
	// 채널설명
	channelDesc: string | undefined = undefined;
	// 채널색상**
	channelColor: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

// 채널 콘텐트 내역 정보
export class ChannelContentRecEntity extends ChannelEntity {
	channelContentSeq = 0;
	// 채널고유번호**
	channelIdx = '';
	// 콘텐트고유번호**
	contentIdx: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}

// 채널 참여자 정보
export class ChannelPartnerEntity extends ChannelEntity {
	// 참여자 회원 고유번호**
	partnerUserIdx: string | undefined = undefined;
	// 채널고유번호**
	channelIdx = '';
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	// 참여자권한타입
	permissionType: USER_PERMISSION_TYPE_CODE = USER_PERMISSION_TYPE_CODE.CHANNEL_USER;
}
