<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getApiClient } from '@/utils/apiClient';
import AppConfig from '@/constants';
import ApocLink from '@/components/common/ApocLink.vue';
import { loadLocalData, setEntityParameters } from '@/utils/common-util';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { getImgUrl } from '@/utils/common-util';
import ApocButton from '@/components/common/ApocButton.vue';
import ApocInput from '@/components/common/ApocInput.vue';
import { ChannelEntityDto, ChannelPartnerEntityDto, SearchChannelPartnerListDto } from '@/api/dto/channel.dto';
import { UserInfoEntityDto } from '@/api/dto/user.dto';
import { getChannelDetail, getChannelPartnerList, saveChannel } from '@/api/channel';
import { getCheckId } from '@/api/user';
import { USER_PERMISSION_TYPE_CODE } from '@/types';
import { POPUP_MODE } from '@/stores/state-store';
import { RESOLUTION_TYPE } from '@/stores/data-store';
import { initStore } from '@/stores/store-manager';

export default defineComponent({
	name: 'EditChannelModal',
	components: { ApocInput, ApocButton, ApocLink, ApocImageSet },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const route = useRoute();
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const channelData = ref<ChannelEntityDto>(new ChannelEntityDto());
		const channelUser = ref();
		const channelPartnerList = ref<ChannelPartnerEntityDto[]>([]);
		const userData = ref<UserInfoEntityDto | null | undefined>(null);
		const loginUser = ref<UserInfoEntityDto>();
		const isCrown = ref<boolean>(false);
		const addUserList = ref<string[]>([]);
		const deleteUserList = ref<string[]>([]);
		const addCrownList = ref<string[]>([]);
		const deleteCrownList = ref<string[]>([]);

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
			const dt = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			if (dt) loginUser.value = JSON.parse(dt);
			getInitData();
			storeManager.stateStore.setChannelInfoEdited(false);
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		const getInitData = () => {
			const param = new ChannelEntityDto();
			param.channelIdx = storeManager.dataStore.popupChannelIdx ? storeManager.dataStore.popupChannelIdx : (route.query.channelIdx as string);
			if (param.channelIdx) {
				getChannelDetail(apiClient, param).then(res => {
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						if (res.data) {
							channelData.value = res.data;
						}
					}
				});
				const searchParam = new SearchChannelPartnerListDto();
				searchParam.channelIdx = storeManager.dataStore.popupChannelIdx ? storeManager.dataStore.popupChannelIdx : (route.query.channelIdx as string);
				getChannelPartnerList(apiClient, searchParam).then(res => {
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						if (res.data) {
							channelPartnerList.value = res.data;
							for (const channelPartnerInfo of channelPartnerList.value) {
								if (
									channelPartnerInfo.permissionType === USER_PERMISSION_TYPE_CODE.CHANNEL_HOST &&
									loginUser.value?.userIdx === channelPartnerInfo.partnerUserIdx
								) {
									isCrown.value = true;
								}
							}
						}
					}
				});
			} else {
				isCrown.value = true;
				if (loginUser.value) {
					const newPartner = new ChannelPartnerEntityDto();
					newPartner.partnerUserIdx = loginUser.value?.userIdx as string;
					channelPartnerList.value?.push(newPartner);
					addUserList.value.push(loginUser.value.userIdx as string);
				}
				setEntityParameters(channelData.value, loginUser.value);
			}
		};

		/**
		 * 채널에 추가할 사람 검색
		 */
		const getUserInfo = () => {
			if (channelUser.value) {
				const originStr = channelUser.value as string;
				const emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
				userData.value = undefined;
				if (emailStr && emailStr.length > 0) {
					const data = { userId: emailStr[0] };
					getCheckId(apiClient, data).then(res => {
						if (res.resultCode === 0 && res.data) userData.value = res.data;
					});
				}
			}
		};

		const addParticipateUser = (vo: UserInfoEntityDto | undefined | null): void => {
			if (vo) {
				//TODO : 채널 최대 인원수에 맞추어서 논의 후 수정 필요 (현재는 50)
				if (channelPartnerList.value.length < 50) {
					const user = channelPartnerList.value?.filter(item => item?.partnerUserIdx === vo.userIdx);
					if (user?.length === 0) {
						const newPartner = new ChannelPartnerEntityDto();
						setEntityParameters(newPartner, vo);
						newPartner.partnerUserIdx = vo.userIdx as string;
						channelPartnerList.value?.push(newPartner); // 전체 참여자 리스트
						addUserList.value.push(vo.userIdx as string);
					}
				} else {
					window.alert(t('msg.ALERT_COLLABORATE_OVER'));
				}
				userData.value = null;
				channelUser.value = '';
				// if (storeManager.dataStore.popupChannelIdx !== '') {
				// 	doAutoSave();
				// }
			}
		};

		const deleteParticipateUser = (index: number, partner: ChannelPartnerEntityDto) => {
			deleteUserList.value?.push(partner.partnerUserIdx);
			addUserList.value.splice(index, 1);
			channelPartnerList.value.splice(index, 1);
		};

		const formCheck = (): boolean => {
			if (!channelData.value) return false;
			if (channelData.value && channelData.value.channelName.trim() === '') {
				window.alert(t('msg.CHANNEL_NAME_EMPTY'));
				return false;
			} else {
				for (const i of channelData.value.channelName) {
					if (channelData.value.channelName[0] === ' ') {
						channelData.value.channelName = channelData.value.channelName.substring(1);
					}
				}
				return true;
			}
		};

		watch(channelUser, () => {
			if (channelUser.value === '') {
				userData.value = null;
			}
		});

		const setRandomColor = (): string => {
			return AppConfig.KEYS.COLORS[Math.floor(Math.random() * 7)];
		};

		const doSave = (): void => {
			if (formCheck() && channelData.value) {
				const param = new ChannelEntityDto();
				param.channelIdx = channelData.value.channelIdx;
				param.channelName = channelData.value.channelName;
				param.channelDesc = channelData.value.channelDesc;
				param.channelColor = channelData.value.channelColor ? channelData.value.channelColor : setRandomColor();

				// 신규 채널 생성 시 생성자 방장 권한
				if (param.channelIdx === '') {
					const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
					if (user && user != null) addCrownList.value.push(JSON.parse(user).userIdx);
				}

				param.userIdxList = addUserList.value;
				param.deleteUserIdxList = deleteUserList.value;

				// 삭제 참여자의 정보는 변경 된 권한 리스트에서 삭제
				deleteUserList.value.forEach(userList => {
					addCrownList.value.forEach((crownList, j) => {
						if (crownList === userList) addCrownList.value.splice(j, 1);
					});

					deleteCrownList.value.forEach((dCrownList, k) => {
						if (dCrownList === userList) deleteCrownList.value.splice(k, 1);
					});
				});

				param.crownUserIdxList = addCrownList.value;
				param.deleteCrownUserIdxList = deleteCrownList.value;
				saveChannel(apiClient, param)
					.then(res => {
						if (res.resultCode !== 0) {
							window.alert(t('msg.' + res.resultMsg));
						} else {
							alert(t('msg.RESULT_SAVE'));
							storeManager.stateStore.setChannelInfoEdited(true);
							closePopup();
						}
					})
					.finally(() => {
						storeManager.stateStore.setChannelInfoEdited(false);
					});
			}
		};

		// const doAutoSave = (): void => {
		// 	console.log('doAutoSave');
		// 	if (formCheck() && channelData.value) {
		// 		const param = new ChannelEntityDto();
		// 		param.channelIdx = channelData.value.channelIdx;
		// 		param.channelName = channelData.value.channelName;
		// 		param.channelDesc = channelData.value.channelDesc;
		// 		param.channelColor = channelData.value.channelColor ? channelData.value.channelColor : setRandomColor();
		// 		param.userIdxList = addUserList.value;
		// 		param.deleteUserIdxList = deleteUserList.value;
		// 		saveChannel(apiClient, param)
		// 			.then(res => {
		// 				if (res.resultCode === 0) {
		// 					addUserList.value = [];
		// 					deleteUserList.value = [];
		//          storeManager.stateStore.setChannelInfoEdited(true);
		// 				}
		// 			})
		// 			.finally(() => {
		//        storeManager.stateStore.setChannelInfoEdited(false);
		// 			});
		// 	}
		// };

		const closePopup = (): void => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			storeManager.dataStore.setPopupChannelIdx('');
		};

		// 왕관 권한 추가 삭제
		const changeCrown = (index: number, partner: ChannelPartnerEntityDto): void => {
			if (isCrown.value) {
				// 왕관 권한이 있는 사람만 왕관 권한 추가/삭제가 가능
				if (channelPartnerList.value[index].permissionType === USER_PERMISSION_TYPE_CODE.CHANNEL_USER) {
					// 대상이 참여자인 경우
					channelPartnerList.value[index].permissionType = USER_PERMISSION_TYPE_CODE.CHANNEL_HOST;
					let checkCount = 0;
					deleteCrownList.value.forEach((value, i) => {
						// 삭제 대상에 존재하는지 체크
						if (value === partner.partnerUserIdx) {
							deleteCrownList.value.splice(i, 1);
							checkCount++;
						}
					});
					if (checkCount === 0) addCrownList.value.push(partner.partnerUserIdx);
				} else if (channelPartnerList.value[index].permissionType === USER_PERMISSION_TYPE_CODE.CHANNEL_HOST) {
					// 대상이 왕관인 경우
					channelPartnerList.value[index].permissionType = USER_PERMISSION_TYPE_CODE.CHANNEL_USER;
					let checkCount = 0;
					addCrownList.value.forEach((value, i) => {
						// 추가 대상에 존재하는지 체크
						if (value === partner.partnerUserIdx) {
							addCrownList.value.splice(i, 1);
							checkCount++;
						}
					});
					if (checkCount === 0) deleteCrownList.value.push(partner.partnerUserIdx);
				}
			}
		};

		return {
			t,
			storeManager,
			loginUser,
			channelData,
			channelUser,
			channelPartnerList,
			userData,
			isCrown,
			getImgUrl,
			getUserInfo,
			addParticipateUser,
			deleteParticipateUser,
			doSave,
			closePopup,
			changeCrown,
			RESOLUTION_TYPE,
			USER_PERMISSION_TYPE_CODE,
		};
	},
});
</script>

<template>
	<div class="channel-popup-bg" @mousedown.self.stop.prevent="closePopup">
		<div :class="{ 'not-admin': !isCrown }" class="popup">
			<div class="popup-header">
				<span>
					{{ t('layout.channelInfo') }}
				</span>
			</div>
			<div class="close-btn" @click="closePopup">
				<i class="fa fa-light fa-xmark"></i>
			</div>
			<div class="popup-content-wrapper">
				<!--            채널이름-->
				<div class="popup-content-title">{{ t('layout.channelName') }}<span class="text--primary">*</span></div>
				<apoc-input
					:model-value="channelData.channelName"
					:placeholder="t('layout.channelNameDes')"
					class="w-10 popup-input-text"
					type="text"
					@update:modelValue="channelData.channelName = $event" />
				<!--            채널설명-->
				<div class="popup-content-title">
					{{ t('layout.DescriptionChannel') }}
				</div>
				<textarea
					v-model="channelData.channelDesc"
					:placeholder="t('layout.DescriptionChannelDes')"
					class="w-10 popup-input-text-des"
					type="text"></textarea>
				<!--            채널 참여자-->
				<div v-if="isCrown" class="popup-content-title">{{ t('layout.channelParticipant') }}</div>
				<div style="position: relative">
					<div v-if="isCrown" class="display-inline-flex w-10">
						<apoc-input
							:model-value="channelUser"
							:placeholder="
								storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.TABLET
									? t('layout.channelParticipantDes')
									: t('layout.channelParticipantDesMobile')
							"
							class="w-10 popup-input-text-participate mr-1"
							type="email"
							@keyup="getUserInfo"
							@update:modelValue="channelUser = $event" />
						<button
							:class="userData ? 'background&#45;&#45;primary' : 'background&#45;&#45;gray'"
							:disabled="!userData"
							class="popup-add-btn"
							@click="addParticipateUser(userData)">
							{{ t('layout.add') }}
						</button>
					</div>
					<!--				채널주인-->
					<div v-if="channelUser" class="search-participate-area">
						<span :class="userData ? 'cursor-pointer' : ''" class="search-participate-wrapper" @click="addParticipateUser(userData)">
							<apoc-image-set :src="getImgUrl(userData?.userProfileImg)" class="popup-img"></apoc-image-set>
							<span class="popup-text">
								{{ userData?.userNicknm ? userData.userNicknm : channelUser }}
							</span>
						</span>
					</div>
				</div>
				<!--            참여자 목록-->
				<div class="popup-participate-area">
					<div v-if="loginUser" class="popup-participate-wrapper">
						<apoc-image-set :src="getImgUrl(loginUser.userProfileImg)" class="popup-participate-img"></apoc-image-set>
						<div class="participate-name-wrapper">
							<div class="participate-name">{{ loginUser.userNicknm }}</div>
							<i class="fa fa-solid fa-crown" :class="isCrown ? 'participate-icon' : 'participate-uncheck-icon'" />
						</div>
					</div>

					<div v-if="channelPartnerList && channelPartnerList?.length > 0" class="popup-participate-section">
						<span v-for="(item, index) in channelPartnerList" :key="index">
							<div v-if="loginUser && item?.partnerUserIdx !== loginUser.userIdx" class="popup-participate-wrapper">
								<apoc-image-set :src="getImgUrl(item?.userProfileImg)" class="popup-participate-img"></apoc-image-set>
								<div class="participate-name-wrapper w-10">
									<span>
										<span class="participate-name">{{ item?.userNicknm }}</span>
										<i
											class="fa fa-solid fa-crown"
											:class="[
												item?.permissionType === USER_PERMISSION_TYPE_CODE.CHANNEL_HOST ? 'participate-icon' : 'participate-uncheck-icon',
												isCrown ? 'cursor-pointer' : '',
											]"
											@click="changeCrown(index, item)" />
									</span>
									<apoc-link v-if="isCrown" :on-click="() => deleteParticipateUser(index, item)" class="participate-delete-wrapper" href="#">
										<i class="fa fa-solid fa-x participate-delete-icon" />
										<span class="participate-delete-text ml-1">{{ t('layout.except') }}</span>
									</apoc-link>
								</div>
							</div>
						</span>
					</div>
				</div>
			</div>
			<div class="save-btn-wrapper">
				<apoc-button @click="doSave">
					{{ t('common.doSave') }}
				</apoc-button>
			</div>
		</div>
	</div>
</template>
<style scoped></style>
