<script lang="ts">
import { getContentList } from '@/api/content';
import AppConfig from '@/constants';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { STATE_YN } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { goAuthor } from '@/utils/apoc-utils';
import { loadLocalData, removeLocalData, saveLocalData } from '@/utils/common-util';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import ApocButton from '@/components/common/ApocButton.vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';

export default defineComponent({
	name: 'MakePopup',
	setup() {
		const storeManager = initStore();
		const router = useRouter();
		const { t } = useI18n();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const disableContinue = ref<boolean>(true);

		const clearData = () => {
			removeLocalData('modifyContentIdx');
			removeLocalData('modifyContentType');
		};
		const goToMyContent = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			storeManager.stateStore.setDirectGoToAuthor(true);
			if (router.currentRoute.value.path !== '/my-contents') {
				// 	router.go(0);
				// } else {
				router.push('/my-contents');
			}
		};
		const makeNewContent = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			clearData();
			saveLocalData('makeNew', STATE_YN.Y);
			storeManager.stateStore.setOpenAuthor(true);

			// ctx : ms-teams 화면 이동을 위한 값
			const param = {
				authorUrl: `${AppConfig.AUTHOR_HOST}author` + `?lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}`,
				ctx: JSON.stringify({
					lang: loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG),
					isNew: true,
				}),
			};
			goAuthor(param, storeManager);
		};

		const checkUserContentCount = () => {
			getContentList(apiClient, {}).then(res => {
				if (res.totalCount > 0) disableContinue.value = false;
			});
		};

		onMounted(() => {
			lockBackgroundScroll();
			const userData = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			if (userData) checkUserContentCount();
		});

		onBeforeUnmount(() => {
			unLockBackgroundScroll();
		});
		return {
			makeNewContent,
			goToMyContent,
			t,
			disableContinue,
		};
	},
	components: { ApocImageSet, ApocButton },
});
</script>

<template>
	<!--	<h2>MAKE POPUP</h2>-->
	<div class="make-popup">
		<div class="card-wrapper">
			<div class="icon-wrapper">
				<apoc-image-set src="/assets/images/common/icons/header/new-start-icon.svg" />
			</div>
			<pre class="des">{{ t('popup.startPopupDes') }}</pre>
			<div class="btn-wrapper">
				<apoc-button @click="makeNewContent">{{ t('popup.start') }}</apoc-button>
			</div>
		</div>
		<div class="card-wrapper">
			<div class="icon-wrapper">
				<apoc-image-set src="/assets/images/common/icons/header/continue-icon.svg" />
			</div>
			<pre class="des">{{ t('popup.continuePopupDes') }}</pre>
			<div class="btn-wrapper">
				<apoc-button :disabled="disableContinue" @click="goToMyContent">{{ t('popup.continue') }}</apoc-button>
			</div>
			<div v-if="disableContinue" class="continue-dimmed"></div>
		</div>
	</div>
	<!--	<h2>popup end</h2>-->
</template>

<style scoped></style>
