<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'ApocButton',
	props: {
		isPrimary: {
			type: Boolean,
			default: false,
			required: false,
		},
		onClick: {
			type: Function as PropType<() => void>,
			default: () => {},
		},
		isDisabled: {
			type: Boolean as PropType<boolean>,
			isRequire: false,
			default: false,
		},
	},
	setup(props) {
		const propsRef = toRefs(props);
		const btnIsDisabled = toRefs(props).isDisabled;

		return {
			...propsRef,
			btnIsDisabled,
		};
	},
});
</script>

<template>
	<button class="apoc-btn" :class="{ primary: isPrimary, secondary: !isPrimary }" :disabled="btnIsDisabled" @click.stop.prevent="onClick">
		<slot />
	</button>
</template>

<style scoped></style>
