import type { AxiosInstance } from 'axios';
import { getApiFileHeader, getApiHeader } from '../utils/apiClient';
import { ResponseDto } from './dto/response.dto';
import { BoardCommentEntityDto, BoardEntityDto, DeleteBoardDto, SearchBoardCommentDto, SearchBoardDto } from './dto/board.dto';
import { BoardEvalEntity } from '@/api/model/board.entity';

/**
 * 게시판 리스트 조회
 * @param client
 * @param param
 */
export function getBoardList(client: AxiosInstance, param: SearchBoardDto): Promise<ResponseDto<BoardEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/board/getBoardList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시판 상세 조회
 * @param client
 * @param param
 */
export function getBoardDetail(client: AxiosInstance, param: SearchBoardDto): Promise<ResponseDto<BoardEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/board/getBoardDetail', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시물 저장
 * @param client
 * @param param
 */
export function saveBoard(client: AxiosInstance, param: any): Promise<ResponseDto<BoardEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/board/saveBoard', param, getApiFileHeader())
			.then(res => {
				fnResolve(new ResponseDto<BoardEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RES제ULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시물 삭제
 * @param client
 * @param param
 */
export function deleteBoard(client: AxiosInstance, param: DeleteBoardDto): Promise<ResponseDto<BoardEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/board/deleteBoard', param, getApiHeader())
			.then(res => {
				fnResolve(new ResponseDto<BoardEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시물 댓글 리스트 조회
 * @param client
 * @param param
 */
export function getBoardCommentList(client: AxiosInstance, param: SearchBoardCommentDto): Promise<ResponseDto<BoardCommentEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardCommentEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/board/getBoardCommentList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardCommentEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시판 댓글 저장 수정
 * @param client
 * @param param
 */
export function saveBoardComment(client: AxiosInstance, param: BoardCommentEntityDto): Promise<ResponseDto<BoardCommentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardCommentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/board/saveBoardComment', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardCommentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시판 댓글 삭제
 * @param client
 * @param param
 */
export function deleteBoardComment(client: AxiosInstance, param: BoardCommentEntityDto): Promise<ResponseDto<BoardCommentEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardCommentEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/board/deleteBoardComment', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardCommentEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시판 좋아요
 * @param client
 * @param param
 */
export function insertBoardEval(client: AxiosInstance, param: BoardEvalEntity): Promise<ResponseDto<BoardEvalEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardEvalEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/board/insertBoardEval', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardEvalEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 게시판 좋아요 삭제
 * @param client
 * @param param
 */
export function deleteBoardEval(client: AxiosInstance, param: BoardEvalEntity): Promise<ResponseDto<BoardEvalEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<BoardEvalEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/board/deleteBoardEval', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<BoardEvalEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
