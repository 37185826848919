<script lang="ts">
import { computed, defineComponent } from 'vue';
import { getImgUrl } from '@/utils/common-util';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'ApocImageSet',
	props: {
		src: {
			type: String as PropType<string>,
			required: true,
		},
		originSrc: {
			type: String as PropType<string>,
		},
		imgSets: {
			type: Number as PropType<number>,
			default: 1,
			required: false,
		},
		alt: {
			type: String as PropType<string>,
			required: false,
		},
		width: {
			type: String as PropType<string>,
			required: false,
		},
		height: {
			type: String as PropType<string>,
			required: false,
		},
		isVertical: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const srcSet = computed(() => {
			if (props.imgSets <= 1) return '';
			const lastDotIndex = props.src.lastIndexOf('.');
			const fileName = props.src.substring(0, lastDotIndex);
			const ext = props.src.substring(lastDotIndex);
			let imgSrcSet = `${props.src} 1x`;
			if (props.imgSets > 1) {
				imgSrcSet += `, ${fileName}@2x${ext} 2x`;
			}
			if (props.imgSets > 2) {
				imgSrcSet += `, ${fileName}@3x${ext} 3x`;
			}
			return imgSrcSet;
		});

		const onError = (e: Event) => {
			if (e.currentTarget) {
				const currentTarget = e.currentTarget as HTMLImageElement;
				currentTarget.onerror = null;
				currentTarget.src = getImgUrl(props.originSrc, 'origin', props.isVertical);
				// if (props.originSrc) {
				// 	currentTarget.classList.remove('failed-image'); // 이미지 로드 성공 시 클래스 제거
				// } else {
				// 	currentTarget.classList.add('failed-image'); // 이미지 로드 실패 시 클래스 추가
				// }
			}
		};

		return {
			srcSet,
			onError,
		};
	},
});
</script>

<template>
	<img :alt="alt" :height="height" :onerror="onError" :src="src" :srcset="srcSet" :width="width" />
</template>

<style scoped></style>
