import type { AxiosInstance } from 'axios';
import { UserInfoEntityDto } from './dto/user.dto';
import { ResponseDto } from './dto/response.dto';

/**
 * 회원친구알림설정 저장
 * @param client
 * @param param
 */
export function insertUserFollowerNoticeConfig(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/notice/insertUserFollowerNoticeConfig', param)
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 회원친구알림설정 삭제
 * @param client
 * @param param
 */
export function deleteUserFollowerNoticeConfig(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/notice/deleteUserFollowerNoticeConfig', param)
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
