const KEYS = {
	LOGIN_USER: 'LUT',
	LOGIN_TOKEN: 'LUT_TK',
	LANG: 'LANG',
	FIRST_PAGE_COUNT: 1,
	PAGING_ROW: 20,
	MORE_LIMIT: {
		MAIN: 8,
		CONTENTS_LIST: 12,
		TEMPLATE: 8,
		MY_CONTENTS: 4,
		MY_CONTENTS_PC: 9,
		DEFAULT: 8,
		CREATOR: 6,
	},
	CONST: {
		LOGIN_USER: 'LUT',
		LOGIN_TOKEN: 'LUT_TK',
		PLAY_INFO: 'PLAY_INFO',
		CHANGE_LANG: 'CHANGE_LANG',
		CAFE24: {
			MALL_ID: 'CMI',
			ACCESS_TOKEN: 'CAT',
			CLIENT_KEY: 'cF7p6pJkF5dsnqnmQWu37C',
		},
	},
	COLORS: ['#CCB6FE', '#FFE7A6', '#E9CEFF', '#B6D3EB', '#BDEED7', '#FFCFB5', '#CBE6FF'],
	EVENTS: {
		DO_PLAY: 'DO_PLAY',
		DO_LIKE: 'DO_LIKE',
		DO_BAD: 'DO_BAD',
		DO_SHARE: 'DO_SHARE',
		DO_FAV_SAVE: 'DO_FAV_SAVE',
		DO_FAV_LIST_DELETE: 'DO_FAV_LIST_DELETE',
		DO_ALARM: 'DO_ALARM',
		DO_SUBSCRIBE: 'DO_SUBSCRIBE',
		DO_PLAY_LIST_DELETE: 'DO_PLAY_LIST_DELETE',
		DO_DECLARATION: 'DO_DECLARATION',
		GO_CRACK_DETAIL: 'GO_CRACK_DETAIL',
		GO_USER_DETAIL: 'GO_USER_DETAIL',
		CLOSE_BOTTOM_MENU: 'CLOSE_BOTTOM_MENU',
		OPEN_FULL_MENU: 'OPEN_FULL_MENU',
		LOAD_PLAY_LIST: 'LOAD_PLAY_LIST',
		LOAD_BUY_LIST: 'LOAD_BUY_LIST',
		LOAD_FAV_LIST: 'LOAD_FAV_LIST',
		LOAD_SUBS_LIST: 'LOAD_SUBS_LIST',
		ADD_COMMENT: 'ADD_COMMENT',
		UPDATE_COMMENT: 'UPDATE_COMMENT',
		DELETE_COMMENT: 'DELETE_COMMENT',
		RELOAD_COMMENT_DATA: 'RELOAD_COMMENT_DATA',
		RELOAD_PAGE_DATA: 'RELOAD_PAGE_DATA',
		GO_APOC_AUTHOR: 'GO_APOC_AUTHOR',
		SEARCH_KEYWORD: 'SEARCH_KEYWORD', // 커뮤니티 키워드 검색 이벤트
		CHANGE_SIDEBAR: 'CHANGE_SIDEBAR', // 커뮤니티 사이드 바 셀렉 이벤트
	},
	MS_TEAMS: {
		// appId: 'bc7ec15c-c24e-4bdc-b992-46980b33597d', // prod
		appId: '49c4d411-360e-4769-b00d-fa294af1155a', // clone
		platformPageId: '8df249f6-7728-49ad-abea-791444df580f', // prod
		authorPageId: '75685453-c9b4-4c90-8067-690d7d87f979', // prod
	},
};
export { KEYS };
