<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'ApocLink',
	props: {
		href: {
			type: String as PropType<string>,
			required: true,
		},
		title: {
			type: String as PropType<string>,
		},
		onClick: {
			type: Function as PropType<(href: string) => void>,
			required: false,
		},
	},
	setup() {
		const router = useRouter();
		return {
			router,
		};
	},
	methods: {
		handleClick(e: Event) {
			if (this.onClick) {
				this.onClick(this.href);
			} else {
				e.stopPropagation();
				this.router.push(this.href);
			}
		},
	},
});
</script>

<template>
	<a class="apoc-link" :href="href" @click.stop.prevent="handleClick" :title="title"><slot></slot></a>
</template>

<style scoped></style>
