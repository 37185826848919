// 결제
import { STATE_YN } from '../../types';

export class SubscriptionEntity {
	// 구독고유번호
	subscriptionIdx: string | undefined = undefined;
	// 사용자고유번호
	userIdx: string | undefined = undefined;
	// 구독상품고유번호
	planIdx: string | undefined = undefined;
	// 구독상태코드
	subscriptionType: string | undefined = undefined;
	// 구독상태변경이유
	stateChangeNote: string | undefined = undefined;
	// 구독상태변경일시
	stateUpdateTime: Date | null = null;
	// 구독시작시간
	startTime: Date | null = null;
	// 구독종료시간
	endTime: Date | null = null;
	// 결제정보
	billingInfo: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	// 총 개수
	totalCount = 0;
}

export class SubscriptionDetailEntity {
	// 구독상세고유번호
	subscriptionDetailIdx: string | undefined = undefined;
	// 구독고유번호
	subscriptionIdx: string | undefined = undefined;
	// 회원고유번호
	userIdx: string | undefined = undefined;
	// 구독 상품 고유번호
	planIdx: string | undefined = undefined;
	// 메타명
	featureCode: string | undefined = undefined;
	// 메타값
	featureValue: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	// 총 개수
	totalCount = 0;
}
