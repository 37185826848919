// 즐겨찾기
import { STATE_YN } from '../../types';
import { FavEntity } from '../model/fav.entity';

export class FavEntityDto extends FavEntity {
	// 즐겨찾기일련번호**
	favSeq = 0;
	// 대상고유번호
	targetIdx: string | undefined = undefined;
	// 즐겨찾기유형코드
	favTypeCode: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
	//사용자 이름
	userName: string | undefined = undefined;
	//사용자 닉네임
	userNicknm: string | undefined = undefined;
	//사용자 프로필
	userProfileImg: string | undefined = undefined;
	//콘텐트 메인 썸네일
	contentMainImg: string | undefined = undefined;
	//플레이 한 수
	playCount = 0;
	// 콘텐트고유번호
	contentIdx = '';
	// 콘텐트명**
	contentName = '';
	shortUrl = '';
	openTypeCode: string | undefined = undefined;
}
