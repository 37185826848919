import type { AxiosInstance } from 'axios';
import { getApiFileHeader, getApiHeader } from '../utils/apiClient';
import { snsLoginParamDto, UserFollowerListDto, UserInfoEntityDto, UserLoginLogEntity } from './dto/user.dto';
import { ResponseDto } from './dto/response.dto';
import { UserFollowerNoticeConfigEntity } from '@/api/model/notice.entity';

/**
 * 사용자 로그인
 * @param client
 * @param param
 */
export function doLogin(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/doLogin', param)
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 사용자 로그아웃
 * @param client
 * @param param
 */
export function doLogout(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/doLogout', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 사용자 회원가입
 * @param client
 * @param param
 */
export function doJoin(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/doJoin', param)
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 사용자 아이디 유효성 체크
 * @param client
 * @param param
 */
export function getCheckId(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/getCheckId', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저정보 조회
 * @param client
 * @param param
 */
export function getUserInfo(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/getUser', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 정보 리스트 조회
 * @param client
 * @param param
 */
export function getUserList(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/getUserList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 닉네임 조회
 * @param client
 * @param param
 */
export function getCheckNickNm(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/getCheckNicknm', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 업데이트
 * @param client
 * @param param
 */
export function updateUser(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/updateUser', param, getApiFileHeader())
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 카카오 로그인
 * @param client
 * @param param
 */
export function kakaoLoginWithApoc(client: AxiosInstance, param: any): Promise<ResponseDto<snsLoginParamDto>> {
	const promiseFn = (
		fnResolve: (value: PromiseLike<ResponseDto<snsLoginParamDto>> | ResponseDto<snsLoginParamDto>) => void,
		fnReject: (reason?: any) => void,
	) => {
		client
			.post('/users/doLoginKakao', param)
			.then(res => {
				fnResolve(new ResponseDto<snsLoginParamDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 마이크로소포트 로그인
 * @param client
 * @param param
 */
export function microsoftLoginWithApoc(client: AxiosInstance, param: any): Promise<ResponseDto<snsLoginParamDto>> {
	const promiseFn = (
		fnResolve: (value: PromiseLike<ResponseDto<snsLoginParamDto>> | ResponseDto<snsLoginParamDto>) => void,
		fnReject: (reason?: any) => void,
	) => {
		client
			.post('/users/doLoginMicrosoft', param)
			.then(res => {
				fnResolve(new ResponseDto<snsLoginParamDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};

	return new Promise(promiseFn);
}

/**
 * 유저 업데이트
 * @param client
 * @param param
 */
export function updateSnsUser(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/updateSnsUser', param)
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 업데이트
 * @param client
 * @param param
 */
export function verifySnsUser(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/verifySnsUser', param)
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 유저 업데이트
 * @param client
 * @param param
 */
export function doLoginFirebase(client: AxiosInstance, param: any): Promise<ResponseDto<snsLoginParamDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<snsLoginParamDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/doLoginFirebase', param)
			.then(res => {
				fnResolve(new ResponseDto<snsLoginParamDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 팔로우 리스트 조회
 * @param client
 * @param param
 */
export function getFollowList(client: AxiosInstance, param: any): Promise<ResponseDto<UserFollowerListDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserFollowerListDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/getFollowList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserFollowerListDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 팔로우 추가
 * @param client
 * @param param
 */
export function insertFollowerUser(client: AxiosInstance, param: any): Promise<ResponseDto<UserFollowerListDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserFollowerListDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/insertFollowerUser', param)
			.then(res => {
				fnResolve(new ResponseDto<UserFollowerListDto[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 팔로우 수정
 * @param client
 * @param param
 */
// export function updateFollowerUser(
// 	client: AxiosInstance,
// 	param: any,
// ): Promise<ResponseDto<UserFollowerNoticeConfigEntity[]>> {
// 	const promiseFn = (
// 		fnResolve: (value: ResponseDto<UserFollowerNoticeConfigEntity[]>) => void,
// 		fnReject: (reason?: any) => void,
// 	) => {
// 		client
// 			.post('/users/updateFollowerUser', param)
// 			.then(res => {
// 				fnResolve(new ResponseDto<UserFollowerNoticeConfigEntity[]>(res.data));
// 			})
// 			.catch(err => {
// 				console.error(err);
// 				fnReject('msg.RESULT_FAILED');
// 			});
// 	};
// 	return new Promise(promiseFn);
// }

/**
 * 팔로우 삭제
 * @param client
 * @param param
 */
export function deleteFollowerUser(client: AxiosInstance, param: any): Promise<ResponseDto<UserFollowerNoticeConfigEntity[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserFollowerNoticeConfigEntity[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/deleteFollowerUser', param)
			.then(res => {
				fnResolve(new ResponseDto<UserFollowerNoticeConfigEntity[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 비밓번호 변경
 * @param client
 * @param param
 */
export function changePwd(client: AxiosInstance, param: any): Promise<ResponseDto<UserFollowerNoticeConfigEntity[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserFollowerNoticeConfigEntity[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/changePwd', param)
			.then(res => {
				fnResolve(new ResponseDto<UserFollowerNoticeConfigEntity[]>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 비밀번호 체크
 * @param client
 * @param param
 */
export function getCheckPwd(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/getCheckPwd', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 저작도구 토큰 생성
 * @param client
 * @param param
 */
export function generateAuthorToken(client: AxiosInstance, param: any): Promise<ResponseDto<UserInfoEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<UserInfoEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/generateAuthorToken', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<UserInfoEntityDto>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}

/**
 * 사용자 아이디 찾기
 * @param client
 * @param param
 */
export function doFindId(client: AxiosInstance, param: any): Promise<ResponseDto<any>> {
	const promiseFn = (fnResolve: (value: ResponseDto<any>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/findId', param)
			.then(res => {
				fnResolve(new ResponseDto<any>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
/**
 * 사용자 비밀번호 찾기
 * @param client
 * @param param
 */
export function doFindPassword(client: AxiosInstance, param: any): Promise<ResponseDto<any>> {
	const promiseFn = (fnResolve: (value: ResponseDto<any>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/findPassword', param)
			.then(res => {
				fnResolve(new ResponseDto<any>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
/**
 * 사용자 비밀번호 찾기
 * @param client
 * @param param
 */
export function verifyEmailToken(client: AxiosInstance, param: any): Promise<ResponseDto<any>> {
	const promiseFn = (fnResolve: (value: ResponseDto<any>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/verifyEmailToken', param)
			.then(res => {
				fnResolve(new ResponseDto<any>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
/**
 * 사용자 비밀번호 변경
 * @param client
 * @param param
 */
export function changeEmailPwd(client: AxiosInstance, param: any): Promise<ResponseDto<any>> {
	const promiseFn = (fnResolve: (value: ResponseDto<any>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/users/changeEmailPwd', param)
			.then(res => {
				fnResolve(new ResponseDto<any>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
