import * as microsoftTeams from '@microsoft/teams-js';
import AppConfig from '@/constants';
import { THIRD_PARTY_TYPE } from '@/stores/common-store';
import { initStore, StoreManager } from '@/stores/store-manager';

export enum TAB_TYPE {
	PLATFORM,
	AUTHOR,
}

class MsTeams {
	private isMsTeams = false;
	private storeManager: StoreManager | undefined;

	constructor() {
		this.storeManager = initStore();
		this.isMsTeams = this.storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.MS_TEAMS;
		if (this.isMsTeams) {
			microsoftTeams.initialize();
		}
	}

	// ms App 간 tab이동
	public navigateToTap(type: TAB_TYPE, ctx?: string): Promise<any> {
		const promiseFn = async (fnResolve: any, fnReject: any) => {
			if (this.isMsTeams) {
				await microsoftTeams.pages
					.navigateToApp({
						appId: AppConfig.KEYS.MS_TEAMS.appId, // ms teams app ID
						pageId: AppConfig.KEYS.MS_TEAMS.authorPageId, // apoc platform tab ID
						webUrl: AppConfig.AUTHOR_HOST + `author`, // 페이지 연결이 안됬을시 연결할 web url
						subPageId: ctx ?? '', // 사용자 파라미터
					})
					.then(res => {
						fnResolve(res);
					})
					.catch(e => {
						fnReject(e);
					});
			}
		};
		return new Promise(promiseFn);
	}

	public async getContext() {
		return await microsoftTeams.app.getContext();
	}

	// public async test() {
	// 	// microsoftTeams.app.getContext().then(res => {
	// 	// 	window.alert(JSON.stringify(res));
	// 	// });
	// 	// await microsoftTeams.pages.navigateToApp({
	// 	// 	appId: AppConfig.KEYS.MS_TEAMS.appId, // ms teams app ID
	// 	// 	pageId: AppConfig.KEYS.MS_TEAMS.platformPageId, // apoc platform tab ID
	// 	// 	// webUrl: AppConfig.FRONT_HOST, // 페이지 연결이 안됬을시 연결할 web url
	// 	// 	subPageId: JSON.stringify({ key: 'test' }), // 사용자 파라미터
	// 	// });
	// 	const k = await microsoftTeams.pages.tabs.getTabInstances();
	// 	this.debug(k);
	// 	this.debug(await microsoftTeams.pages.tabs.getTabInstances());
	// 	this.debug(await microsoftTeams.app.getContext({ entityId: AppConfig.KEYS.MS_TEAMS.authorPageId }));
	// }

	private debug(v: any) {
		window.alert(JSON.stringify(v));
	}
}
let msTeams: MsTeams | undefined;
export const getMsTeams = () => {
	if (!msTeams) msTeams = new MsTeams();
	return msTeams;
};
