<script lang="ts">
import ApocCollapse from '@/components/common/ApocCollapse.vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import FooterMenu from '@/components/footer/FooterMenu.vue';

export default defineComponent({
	name: 'MainFooter',
	components: { ApocImageSet, ApocCollapse, FooterMenu },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const isOpenInfo = ref<boolean>(false);

		const onClickInfo = () => {
			isOpenInfo.value = !isOpenInfo.value;
		};

		const apocMenuOptions = [
			{ href: '/', label: t('layout.header.content') },
			{ href: '/', label: t('layout.header.method') },
			{ href: '/', label: t('layout.header.example') },
			{ href: '/', label: t('layout.header.community') },
			{ href: '/', label: t('layout.header.costPolicy') },
		];

		const companyMenuOptions = [
			{ href: '/', label: t('layout.footer.company.companyInfo') },
			{ href: '/', label: t('layout.footer.company.employment') },
			{ href: '/', label: t('layout.footer.company.partner') },
			{ href: '/', label: t('layout.footer.company.service') },
			{ href: '/', label: t('layout.footer.company.newsBlog') },
		];

		const customerServiceOptions = [
			{ href: '/', label: t('layout.footer.customerService.FAQ') },
			{ href: '/service/terms?lang=en', label: t('layout.footer.customerService.useTerms') },
			{ href: '/service/policy?lang=en', label: t('layout.footer.customerService.infoTerms') },
			{ href: '/', label: t('layout.footer.customerService.copyright') },
			{ href: '/', label: t('layout.footer.customerService.inquiry') },
		];

		return {
			t,
			isOpenInfo,
			apocMenuOptions,
			companyMenuOptions,
			customerServiceOptions,
			onClickInfo,
		};
	},
});
</script>

<template>
	<ClientOnly>
		<footer>
			<div class="grid footer">
				<div class="footer-menu footer-info">
					<apoc-image-set class="main-logo" src="/assets/images/common/logo/main_logo.webp" />
					<div class="introduce">{{ t('layout.footer.introduce') }}</div>
					<!--				<div class="socials">-->
					<!--					<apoc-image-set src="/assets/images/common/icons/spotify.svg" />-->
					<!--					<apoc-image-set src="/assets/images/common/icons/instagram.svg" />-->
					<!--					<apoc-image-set src="/assets/images/common/icons/youtube.svg" />-->
					<!--				</div>-->
				</div>
				<!--			<footer-menu class="footer-apoc-menu" :titleText="t('layout.footer.info.company')" :optionList="apocMenuOptions" />-->
				<!--			<footer-menu class="footer-company-menu" :titleText="t('layout.footer.company.companyMenu')" :optionList="companyMenuOptions" />-->
				<!--			<footer-menu-->
				<!--				class="footer-customer-service"-->
				<!--				:titleText="t('layout.footer.customerService.customerService')"-->
				<!--				:optionList="customerServiceOptions" />-->
				<div class="footer-bottom">
					<div class="footer-bottom-wrapper">
						<div class="footer-bottom-menu">
							<div>
								{{ t('layout.footer.copyright1-1') }}
								<strong>{{ t('layout.footer.copyright1-2') }}</strong>
								{{ t('layout.footer.copyright1-3') }}
							</div>
							<!-- <div>{{ t('layout.footer.useTerms') }}</div> -->
							<!-- <div>{{ t('layout.footer.infoTerms') }}</div> -->
							<!-- <apoc-collapse @click="onClickInfo">
								<template v-slot:label>
									{{ t(`layout.footer.companyInfo`) }}
								</template>
							</apoc-collapse> -->
						</div>
						<div>{{ t('layout.footer.copyright2') }}</div>
					</div>
					<transition name="dropdown">
						<div v-if="isOpenInfo" class="footer-bottom-open">
							<span>{{ t('layout.footer.info.company') }}</span>
							<span>{{ t('layout.footer.info.ceo') }}</span>
							<span>{{ t('layout.footer.info.businessNo') }}</span>
							<span>{{ t('layout.footer.info.communicateNo') }}</span>
							<span>{{ t('layout.footer.info.searchBusiness') }}</span>
							<span
								><a :href="'mailto:' + t('layout.footer.info.email')">{{ t('layout.footer.info.email') }}</a>
							</span>
							<span
								><a :href="'tel:' + t('layout.footer.info.customerCenterNo')">{{ t('layout.footer.info.customerCenterNo') }}</a></span
							>
							<span>{{ t('layout.footer.info.address') }}</span>
						</div>
					</transition>
				</div>
			</div>
		</footer>
	</ClientOnly>
</template>

<style scoped></style>
