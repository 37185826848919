import { BasicListDto } from './request.dto';
import { CategoryConnectRecEntity, CategoryEntity } from '../model/category.entity';
import { ASSET_STATE_CODE, CATEGORY_TAB, CATEGORY_TYPE_CODE, ELEMENT_TYPE, PARENT_CATEGORY_TYPE_CODE, STATE_YN } from '../../types';

/**
 * 카테고리 검색용 DTO
 */
export class SearchCategoryListDto extends BasicListDto {
	categoryIdx = '';
	categoryCode = '';
	categoryTypeCode: CATEGORY_TYPE_CODE | undefined = undefined;
	regrUserIdx = '';
	likeCategoryCode = '';
	categoryTab: CATEGORY_TAB | undefined = undefined;
	parentCategoryTypeCode: PARENT_CATEGORY_TYPE_CODE | undefined = undefined;
}

/**
 * 카테고리 연결내역 검색용 DTO
 */
export class SearchCategoryConnectRecListDto extends BasicListDto {
	categoryConnectSeq = 0;
	categoryIdx = '';
	targetIdx = '';
	openYn: STATE_YN = STATE_YN.N;
	regrUserIdx = '';
	assetIdx = '';
}

/**
 * 카테고리 확장형 DTO
 */
export class CategoryEntityDto extends CategoryEntity {
	//반환 총갯수
	totalCount = 0;
	// 포함된 어셋 갯수
	assetCount = 0;
	//카테고리 이미지
	categoryMainImg = '';
	//유저 고유번호
	userIdx = '';
	//유저 아이디
	userId = '';
	//유저 닉네임
	userNicknm = '';
	//유저 이름
	userName = '';
	//유저 영문이름
	userEnName = '';
	shortUrl = '';
}

/**
 * 카테고리 연결 내역 확장형 DTO
 */
export class CategoryConnectRecEntityDto extends CategoryConnectRecEntity {
	// 반환 총갯수
	totalCount = 0;
	// 어셋 고유번호 : assetIdx, contentIdx 통칭
	assetIdx = '';
	// 어셋 이름 : assetName, contentName 통칭
	assetName = '';
	// 어셋 영문 이름
	assetEnName = '';
	// 어셋 상태 코드
	assetStateCode: ASSET_STATE_CODE | undefined = undefined;
	// 어셋 분류 코드 : MUSIC, IMAGE..
	assetTypeCode: ELEMENT_TYPE | undefined = undefined;
	// 에셋 태그 내용
	assetTagBody = '';
	// 저작권 등록 고유번호
	cprIdx = '';
	// 상품 등록 여부
	productRegYn: STATE_YN = STATE_YN.N;
	// 플레이 타임
	playTime = '';
	// 간단한 설명
	summaryDesc = '';
	// 유아콘텐츠 유무
	kidYn: STATE_YN = STATE_YN.N;
	// 외부 컨텐트 연결 여부
	externalContentConnectYn: STATE_YN = STATE_YN.N;
	// 유료 여부
	chargeYn: STATE_YN = STATE_YN.N;
	//콘텐트 메인 이미지
	contentMainImg = '';
	// 사용자 고유번호
	userIdx = '';
	// 사용자 아이디
	userId = '';
	// 사용자 닉네임
	userNicknm = '';
	// 사용자 이름
	userName = '';
	// 로그인 유저의 즐겨찾기 유무
	favAsset = '';
	// 좋아요 총 갯수
	favCount = 0;
	// 읽음 총 갯수
	readCount = 0;
	// 플레이 카운트
	playCount = 0;
	// 어셋 메타 정보
	assetMetaList: any = undefined;
	// 유투브 url
	youtubeUrl = '';
	// 등록된 메인 썸네일
	assetImg = '';
	// 등록된 영문 썸네일
	assetEnImg = '';
	// 실제 소스파일
	assetFile = '';
	// 유저 프로필
	userProfileImg = '';
	// 내가 즐겨찾기 했는지
	favContent: STATE_YN | null = null;
	shortUrl: string | undefined = undefined;
	contentIdx = '';
	contentName = '';
}
