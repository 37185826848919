import type { AxiosInstance } from 'axios';
import { ResponseDto } from '@/api/dto/response.dto';
import { getApiHeader } from '@/utils/apiClient';
import { DefectEntity } from '@/api/model/defect.entity';

/**
 * 신고 저장
 * @param client
 * @param param
 */
export function insertDefect(client: AxiosInstance, param: DefectEntity): Promise<ResponseDto<DefectEntity>> {
	const promiseFn = (fnResolve: (value: ResponseDto<DefectEntity>) => void, fnReject: (reason?: any) => void) => {
		client
			.post('/defect/insertDefect', param, getApiHeader())
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<DefectEntity>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
