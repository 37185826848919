<script lang="ts">
import type { HeaderSelectOption } from '@/components/common/apocSelect';
import type { PropType } from 'vue';
import { defineComponent, ref, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { useI18n } from 'vue-i18n';

// 헤더의 마이페이지 메뉴 누르면 나오는 드롭다운 메뉴
export default defineComponent({
	name: 'HeaderSelect',
	components: { ApocImageSet },
	props: {
		defaultValue: {
			type: String || Number,
		},
		optionList: {
			type: Array as PropType<HeaderSelectOption[]>,
			required: true,
		},
		isContentHeader: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	setup(props) {
		const listOpened = ref<boolean>(false);
		const router = useRouter();
		const route = useRoute();
		const headerList = toRef(props, 'optionList');
		const { t } = useI18n({ useScope: 'global' });

		const handleSelect = (v?: HeaderSelectOption) => {
			if (v) {
				if (v.router) {
					router.push(v.router);
				}
				if (v.func) v.func();
			}
			listOpened.value = false;
		};
		const handleSelectPlaceholder = () => {
			listOpened.value = false;
		};
		const clickSelectBox = () => {
			listOpened.value = !listOpened.value;
		};
		const closeOptionList = () => {
			listOpened.value = false;
		};

		watch(props.optionList, () => {
			headerList.value = props.optionList;
		});

		watch(route, () => {
			// 주소가 바뀌면 드롭다운 메뉴가 무조건 닫히도록
			closeOptionList();
		});

		return {
			t,
			handleSelect,
			clickSelectBox,
			closeOptionList,
			handleSelectPlaceholder,
			listOpened,
			headerList,
		};
	},
});
</script>

<template>
	<div v-click-away="closeOptionList" class="apoc-select">
		<div class="selected-area" @click="clickSelectBox">
			<p>MY</p>
		</div>
		<transition name="dropdown">
			<div v-show="listOpened" class="header-content option-list-area-wrapper">
				<ul class="option-list-area">
					<li
						v-for="(option, index) of headerList"
						:key="option.value"
						:value="option.value"
						:class="`option-list-item-${index}`"
						@click="handleSelect(option)">
						<object v-show="option.imageSrc" class="option-list-image" :data="option.imageSrc ?? ''" />
						<span v-if="option.value === 'profile-change'" id="profile-change-menu">
							<div>
								<div>{{ option.label }}</div>
								<div>{{ t('layout.header.my.profileChange') }}</div>
							</div>
							<apoc-image-set class="header-arrow" src="/assets/images/common/icons/header/icon_arrow-right.svg" />
						</span>
						<span v-else-if="option.value === 'studio'" id="studio-menu">
							<div>{{ option.label }}</div>
							<apoc-image-set class="header-arrow" src="/assets/images/common/icons/header/icon_open-new-window 1.svg" />
						</span>
						<span v-else>{{ option.label }}</span>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<style></style>
