<script lang="ts">
import ApocLink from '@/components/common/ApocLink.vue';
import type { PropType } from 'vue';

export default {
	name: 'FooterMenu',
	components: { ApocLink },
	props: {
		titleText: {
			type: String,
			required: true,
		},
		optionList: {
			type: Array as PropType<Array<{ href: string; label: string }>>,
		},
	},
	setup(props) {
		return {};
	},
};
</script>

<template>
	<div class="footer-menu">
		<h2>{{ titleText }}</h2>
		<apoc-link v-for="(option, index) of optionList" :key="index" :href="option.href">{{ option.label }}</apoc-link>
	</div>
</template>

<style scoped></style>
