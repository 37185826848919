<script lang="ts">
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import ApocInput from '@/components/common/ApocInput.vue';
import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { updateSimplyInfoContent } from '@/api/content';
import { ContentEntityDto } from '@/api/dto/content.dto';
import AppConfig from '@/constants';
import { getApiClient } from '@/utils/apiClient';
import ApocImageSet from '@/components/common/ApocImageSet.vue';

export default defineComponent({
	name: 'ContentsRenamePopup',
	components: { ApocImageSet, ApocInput },
	setup() {
		const { t } = useI18n();
		const contentName = ref<string | null>(null);
		const ready = ref<boolean>(false);
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const contentInfo = ref(new ContentEntityDto());
		const eventAccess = ref<boolean>(true);

		const closePopup = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			storeManager.dataStore.setContentsTargetIdx('');
			storeManager.dataStore.setContentsTargetInfo(undefined);
		};

		const onEnterEvent = (e: KeyboardEvent) => {
			if (e.code === 'Enter' && eventAccess.value) {
				eventAccess.value = false;
				doRenameContents();
			}
		};

		const doRenameContents = () => {
			if (contentInfo.value.contentName) {
				const param = new ContentEntityDto();
				param.contentIdx = storeManager.dataStore.contentsTargetIdx as string;
				param.contentName = contentInfo.value.contentName;
				updateSimplyInfoContent(apiClient, param)
					.then(res => {
						if (res.resultCode !== 0) {
							window.alert(t(`msg.${res.resultMsg}`));
						} else {
							window.alert(t(`msg.RESULT_MODIFY`));
						}
						storeManager.dataStore.setContentsTargetIdx(undefined);
						storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
						storeManager.dataStore.setContentsTargetInfo(undefined);
						eventAccess.value = true;
						window.location.reload();
					})
					.catch(() => {
						eventAccess.value = true;
					});
			}
		};

		watch(
			() => storeManager.dataStore.contentsTargetInfo,
			() => {
				if (storeManager.dataStore.contentsTargetInfo) {
					contentInfo.value = storeManager.dataStore.contentsTargetInfo;
				}
			},
		);

		onMounted(() => {
			if (storeManager.dataStore.contentsTargetInfo) {
				document.body.style.overflow = 'hidden';
				// document.body.style.position = 'fixed';
				document.body.style.height = '100%';
				document.body.style.width = '100%';
				contentInfo.value = storeManager.dataStore.contentsTargetInfo;
				const textForm: HTMLInputElement = document.querySelector('.apoc-input input') as HTMLInputElement;
				textForm.focus();
			}
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			// countValue,
			ready,
			contentInfo,
			contentName,
			// changeCountValue,
			closePopup,
			onEnterEvent,
			doRenameContents,
		};
	},
});
</script>
<template>
	<div class="contents-rename-popup">
		<div class="close-btn" @click="closePopup"><apoc-image-set src="/assets/images/icon-category/close-icon-navy.svg" /></div>
		<div class="body">
			<h1 class="title">
				{{ t('popup.changeTitle') }}
			</h1>
			<div class="count-area">
				<apoc-input
					:model-value="contentInfo.contentName"
					type="text"
					:placeholder="contentInfo ? contentInfo.contentName : ''"
					:length="200"
					@update:modelValue="contentInfo.contentName = $event"
					@keydown="onEnterEvent" />
				<div class="btn-wrapper">
					<button :class="{ ready: contentInfo.contentName ? contentInfo.contentName.length > 0 : '' }" @click="doRenameContents">
						{{ t('popup.change') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
