<script lang="ts">
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { HeaderSelectOption } from '@/components/common/apocSelect';
import { PropType, defineComponent, ref, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

//콘텐츠 SelectBox
export default defineComponent({
	name: 'ContentsOptionSelect',
	components: { ApocImageSet },
	props: {
		optionList: {
			type: Array as PropType<HeaderSelectOption[]>,
			required: true,
		},
		placeholder: {
			type: String as PropType<string>,
		},
		//이미지
		placeholderImgSrc: {
			type: String as PropType<string>,
			default: '',
			required: false,
		},
		//아이콘
		placeholderIconSrc: {
			type: String as PropType<string>,
			default: '',
			required: false,
		},
		//아래 화살표 이미지
		arrowImgSrc: {
			type: String as PropType<string>,
			default: '',
			required: false,
		},
		onChange: {
			type: Function,
			default: () => ({}),
		},
		//true: select, false: button 으로 쓰임
		isSelect: {
			type: Boolean as PropType<boolean>,
			default: true,
		},
		//option 선택 후 css 변경(primary Color로) 원하면 true
		isActive: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		// option-list가 열리지 않도록
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		//purple arrow 사용 유무
		isPurple: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		//값 같을 때 보라색 체크 표시 유무
		isCheck: {
			type: Boolean as PropType<boolean>,
			default: true,
		},
		//팝업 - select box에서 쓰입니다.
		selectOptionParent: {
			type: Object as PropType<HTMLElement | undefined>,
			required: false,
		},
		//팝업 - select box에서 쓰입니다.
		align: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		//creator 화면에서 플리킹과 중첩되어 select box가 가려지는 경우 필요합니다.
		listItemWrapper: {
			type: Object as PropType<HTMLDivElement | undefined>,
			required: false,
			default: undefined,
		},
	},
	setup(props) {
		const listOpened = ref<boolean>(false);
		const router = useRouter();
		const route = useRoute();
		const selectedValue = ref<string>(props.placeholder ? props.placeholder : '');
		const selectedValueImg = ref<string>(props.placeholderImgSrc ? props.placeholderImgSrc : '');
		const isCSSActive = ref<boolean>(false);
		const listItemWrapperElement = ref<HTMLDivElement | undefined>(props.listItemWrapper || undefined);

		//select list 위치 관련
		let parentElement = props.selectOptionParent || window.document.body;
		const selectedValueElement = ref<HTMLElement | null>(null);
		const optionListElement = ref<HTMLElement | null>(null);

		const handleSelect = (v?: HeaderSelectOption) => {
			if (v) {
				if (v.router) {
					router.push(v.router);
				}
				if (props.onChange) {
					props.onChange(v);
					// selectedValue.value = v.label;
				}
				if (props.isSelect && props.placeholderImgSrc) selectedValueImg.value = v.imageSrc as string;
				if (v.func) v.func();
				//select 조건으로 쓰일 경우에만 값 바뀌도록
				if (props.isSelect) selectedValue.value = v.label;
				//option 선택 후 css 변경 원하면
				if (props.isActive) isCSSActive.value = true;
			}
			listOpened.value = false;
		};
		const handleSelectPlaceholder = () => {
			listOpened.value = false;
		};
		const clickSelectBox = () => {
			//옵션 리스트가 열리도록 한 경우
			if (!props.disabled) {
				if (listOpened.value) {
					//닫는 경우
					listOpened.value = false;
					if (listItemWrapperElement.value) {
						listItemWrapperElement.value?.parentElement?.parentElement?.classList.remove('overflow-unset');
					}
				} else {
					//여는 경우
					listOpened.value = true;
					if (listItemWrapperElement.value) {
						listItemWrapperElement.value?.parentElement?.parentElement?.classList.add('overflow-unset');
					}
				}
			}
		};
		const closeOptionList = () => {
			listOpened.value = false;
			if (listItemWrapperElement.value) listItemWrapperElement.value?.parentElement?.parentElement?.classList.remove('overflow-unset');
		};

		const handleScroll = () => {
			setOptionListStyle();
		};

		const handleResize = () => {
			setOptionListStyle();
		};

		//부모 요소에 따라 select list 위치 조정 TODO 스튜디오 select box와 통일 필요
		const setOptionListStyle = () => {
			// if (props.selectOptionParent && selectedValueElement.value && optionListElement.value) {
			// 	console.log('22');
			// 	// width는 계속 업데이트
			// 	optionListElement.value.style.width = selectedValueElement.value.getBoundingClientRect().width + 'px';
			// 	if (
			// 		parentElement.getBoundingClientRect().bottom - selectedValueElement.value.getBoundingClientRect().top <= 0 ||
			// 		selectedValueElement.value.getBoundingClientRect().bottom - parentElement.getBoundingClientRect().top <= 0
			// 	) {
			// 		return;
			// 	}
			//
			// 	if (
			// 		parentElement.getBoundingClientRect().bottom -
			// 			(selectedValueElement.value.getBoundingClientRect().bottom + optionListElement.value.getBoundingClientRect().height) <=
			// 		0
			// 	) {
			// 		optionListElement.value.style.top =
			// 			selectedValueElement.value.getBoundingClientRect().top - optionListElement.value.getBoundingClientRect().height + 'px';
			// 	} else {
			// 		optionListElement.value.style.top = selectedValueElement.value.getBoundingClientRect().bottom + 'px';
			// 	}
			//
			// 	if (!props.align) {
			// 		optionListElement.value.style.left = selectedValueElement.value.getBoundingClientRect().left + 'px';
			// 	} else {
			// 		optionListElement.value.style.left =
			// 			selectedValueElement.value.getBoundingClientRect().left -
			// 			optionListElement.value.clientWidth +
			// 			selectedValueElement.value.clientWidth +
			// 			'px';
			// 		optionListElement.value.style.top = selectedValueElement.value.getBoundingClientRect().bottom + 'px';
			// 	}
			// }
		};

		watch(
			() => props.selectOptionParent,
			() => {
				parentElement.removeEventListener('scroll', handleScroll);
				parentElement = props.selectOptionParent || window.document.body;
				parentElement.addEventListener('scroll', handleScroll);
			},
		);

		watch(
			() => optionListElement.value,
			() => {
				if (optionListElement.value) {
					setOptionListStyle();
				}
			},
		);

		watch(
			() => props.placeholder,
			(current, prev) => {
				selectedValue.value = current as string;
			},
		);

		watch(
			() => props.placeholderImgSrc,
			(current, prev) => {
				selectedValueImg.value = current as string;
			},
		);

		watch(route, () => {
			// 주소가 바뀌면 드롭다운 메뉴가 무조건 닫히도록
			closeOptionList();
		});

		onMounted(() => {
			window.addEventListener('resize', handleResize);
			parentElement.addEventListener('scroll', handleScroll);
		});

		onUnmounted(() => {
			window.removeEventListener('resize', handleResize);
			parentElement.removeEventListener('scroll', handleScroll);
		});

		return {
			listOpened,
			selectedValue,
			selectedValueImg,
			isCSSActive,
			selectedValueElement,
			optionListElement,
			handleSelect,
			clickSelectBox,
			closeOptionList,
			handleSelectPlaceholder,
		};
	},
});
</script>
<template>
	<div v-click-away="closeOptionList" class="apoc-select content-select" :class="isCSSActive ? 'active' : ''" @click="clickSelectBox">
		<div ref="selectedValueElement" class="selected-area" :class="listOpened ? 'active' : ''">
			<apoc-image-set v-show="placeholderImgSrc" class="placeholder-img" :src="selectedValueImg" />
			<p class="selected-value"><i v-show="placeholderIconSrc" :class="placeholderIconSrc" />{{ selectedValue ?? '' }}</p>
			<div v-show="isSelect" class="select-arrow-down">
				<apoc-image-set
					:class="{ active: listOpened }"
					:src="
						arrowImgSrc
							? arrowImgSrc
							: isCSSActive || isPurple
								? '/assets/images/common/icons/arrow-down2-purple.svg'
								: '/assets/images/common/icons/arrow-down-large-navy.svg'
					" />
			</div>
		</div>
		<transition name="dropdown">
			<!--			<teleport v-if="listOpened && selectOptionParent" to="#apoc-select-options">-->
			<div v-show="listOpened" ref="optionListElement" class="option-list-area-wrapper">
				<ul class="option-list-area">
					<li
						v-for="(option, index) of optionList"
						:key="option.value"
						:value="option.value"
						:class="`option-list-item-${index}`"
						@click="handleSelect(option)">
						<!-- 왼쪽 아이콘 이미지-->
						<apoc-image-set v-show="option.imageSrc" class="option-list-image" :src="option.imageSrc ?? ''" />
						<div class="option-list-desc">
							<!-- 타이틀 -->
							<span>{{ option.label }}</span>
							<!-- 부가 설명 -->
							<span v-show="option.subLabel">{{ option.subLabel }}</span>
						</div>
						<!-- 선택된 항목 체크 표시 -->
						<apoc-image-set
							v-if="isCheck && selectedValue === option.label"
							class="option-list-check"
							src="/assets/images/common/icons/mypage/icon_check-purple.svg" />
					</li>
				</ul>
			</div>
			<!--			</teleport>-->
		</transition>
	</div>
</template>
