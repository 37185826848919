import { BasicListDto } from './request.dto';
import { SHORT_TYPE_CODE, STATE_YN } from '../../types';
import { ShortUrlEntity } from '@/api/model/common.entity';

/**
 * 공통코드 검색용 DTO
 */
export class SearchCommonCodeListDto extends BasicListDto {
	codeTypeCode?: string;
	code?: string;
	codeTypeName?: string;
	codeName?: string;
	codeTypeEnName?: string;
	codeEnName?: string;
	regrUserIdx?: string;
}

/**
 * 첨부파일 검색용 DTO
 */
export class SearchAttachFileListDto extends BasicListDto {
	fileIdx?: string;
	fileTypeCode?: string;
	targetIdx?: string;
	mimeTypeCode?: string;
	regrUserIdx?: string;
}

/**
 * 신고내역 검색용 DTO
 */
export class SearchDefectListDto extends BasicListDto {
	defectSeq = 0;
	defectTargetIdx?: string;
	defectTypeCode?: string;
	defectProcessStateCode?: string;
	defectProcessorUserIdx?: string;
	defectProcessDt: Date | null = null;
	regrUserIdx?: string;
}

/**
 * 즐겨찾기 검색용 DTO
 */
export class SearchFavListDto extends BasicListDto {
	favSeq?: 0;
	targetIdx?: string;
	favTypeCode?: string;
	regrUserIdx?: string;
}

/**
 * 팝업 검색용 DTO
 */
export class SearchPopupListDto extends BasicListDto {
	popupSeq = 0;
	popupCategoryCode?: string;
	startDt: Date | null = null;
	endDt: Date | null = null;
	openYn?: STATE_YN;
	regrUserIdx?: string;
}

/**
 * 배너 검색용 DTO
 */
export class SearchBannerListDto extends BasicListDto {
	bannerIdx?: string;
	connectIdx?: string;
	openYn?: STATE_YN;
	connectTypeCode1?: string;
	connectTypeCode2?: string;
	connectTypeCode3?: string;
	connectTypeCode4?: string;
	bannerTypeCode?: string;
	regrUserIdx?: string;
}

/**
 * 단축 URL 검색용 DTO
 */
export class SearchShortUrlListDto extends BasicListDto {
	shortIdx?: string;
	shortTypeCode?: SHORT_TYPE_CODE;
	targetIdx?: string;
	targetUrl?: string;
	regrUserIdx?: string;
}

/**
 * 단축 URL DTO
 */
export class ShortUrlEntityDto extends ShortUrlEntity {
	//반환 총갯수
	totalCount = 0;
}
