import { STATE_YN, USER_FOLLOWER_NOTICE_TYPE_CODE } from '../../types';

// 회원알림설정
export class UserNoticeConfigEntity {
	// 회원고유번호**
	userIdx: string | undefined = undefined;
	// 회원알림유형코드**
	userNoticeTypeCode: string | undefined = undefined;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
}

// 회원친구알림설정
export class UserFollowerNoticeConfigEntity {
	// 회원친구일련번호
	userFollowerSeq = 0;
	// 회원친구알림유형코드**
	userFollowerNoticeTypeCode: USER_FOLLOWER_NOTICE_TYPE_CODE | null = null;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
}

// 알림큐
export class NoticeQueueEntity {
	// 알림고유번호
	noticeIdx: string | undefined = undefined;
	// 알림대상유형코드**
	noticeTargetTypeCode: string | undefined = undefined;
	// 알림대상고유번호**
	noticeTargetIdx: string | undefined = undefined;
	// 수신자회원고유번호**
	recipientUserIdx: string | undefined = undefined;
	// 관리자일괄알림유형코드
	adminBatchNoticeTypeCode: string | undefined = undefined;
	// 알림내용**
	noticeBody: string | undefined = undefined;
	// 알림발송유형코드
	noticeSendTypeCode: string | undefined = undefined;
	// 알림발송예약일시
	noticeSendResvDt: Date | null = null;
	// 알림발송상태코드
	noticeSendStateCode: string | undefined = undefined;
	// 실패횟수
	failCount = 0;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
}
