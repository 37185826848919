import { defineStore } from 'pinia';
import { ref } from 'vue';
import { STATE_YN, CANVAS_MODE } from '@/types';
import { removeLocalData, saveLocalData } from '@/utils/common-util';
import { ContentEntityDto } from '@/api/dto/content.dto';

export enum RESOLUTION_TYPE {
	S_MOBILE = 1,
	MOBILE = 2,
	TABLET = 3,
	S_PC = 4,
	PC = 5,
	//S_TABLET
}

export interface modifyContentTypeInterface {
	BDL: 'BDL'; // template conetents
	APC: 'APC'; // contents
}

export interface PageInterface {
	resolution: RESOLUTION_TYPE;
	keywords: string;
	pageNo: number;
	rowCount: number;
	categoryCode: string;
	orderType: string;
}

export const useDataStore = defineStore('data', () => {
	const authToken = ref(''); // 로그인 인증 토큰
	const scrollPosition = ref<number | null>(null); // 뒤로 가기 대비, 이전 스크롤 위치 기억
	const page = ref<PageInterface>({
		resolution: RESOLUTION_TYPE.PC,
		keywords: '',
		pageNo: 1,
		rowCount: 8,
		categoryCode: '',
		orderType: '',
	}); // 페이지에 관련된 정보(해상도, 키워드)
	const headerTitle = ref<string | undefined>(''); // header의 제목 값
	const snsRedirectState = ref<string | undefined>(''); // 유저 로그인 플랫폼에 대한 정보(카카오, 구글 등)를 저장하고 있는 값
	const popupReportTargetId = ref<string>(''); // 사용자가 신고 시에 신고의 대상이 되는 상대방의 idx 값
	const popupReportTargetType = ref<string>(''); // 신고가 접수된 글의 타입에 대한 값
	const savedCrackIdxList = ref<Array<string>>([]); // 사용자가 저장한 콘텐츠들의 리스트(마이 페이지 저장한 콘텐츠 조회 시에 사용됨)
	const contentsTargetIdx = ref<string | undefined>(''); // 내 콘텐츠 관리에서 삭제나 복사 시에 해당하는 콘텐츠의 idx 값
	const loginUserUuid = ref<string>(''); // 로그인한 사용자의 uuid의 값
	const followUserUuid = ref<string>(''); // 팔로우 클릭 시 대상 사용자의 uuid의 값
	const isFav = ref<STATE_YN | null | undefined>(null);
	const isHate = ref<STATE_YN | null | undefined>(null);
	const popupContentId = ref<string>(''); // 사용자가 콘텐츠 더보기(⋮)로 클릭한 콘텐츠의 ID 값
	const popupShortUrl = ref<string>(''); //사용자가 콘텐츠 더보기로 클릭한 콘텐츠의 url
	const popupContentsOptionType = ref<string>(''); // 모바일에서 더보기(⋮)를 통해 댓글을 지우려 할 때 콘텐츠인지 커뮤니티 글 인지 구분하기 위하 타입
	const modifyContentIdx = ref<string | undefined>(undefined); // 사용자가 수정하기를 누른 콘텐츠의 idx 값
	const modifyContentType = ref<modifyContentTypeInterface | undefined>(undefined); // 콘텐츠 타입(템플릿, 아폭)에 대한 값
	const contentsTargetInfo = ref<ContentEntityDto | undefined>(undefined); // 내 콘텐츠 관리에서 해당하는 텐츠에 대한 정보
	const copyrightContentIdx = ref<string>(''); // 콘텐츠 출처에 대한 idx 값
	const popupChannelIdx = ref<string | undefined>(''); // 사용자가 채널 수정을 위해 수정하기 클릭한 채널의 idx 값
	const contentsCanvasType = ref<CANVAS_MODE | undefined>(undefined); // 콘텐츠의 화면 설정(가로, 세로, 정방형, 사용자 정의)
	const bottomSheetNum = ref<number>(0);
	const pageApiTotalCount = ref<number>(0); // 현재 페이지의 총 API 갯수
	const loadedCount = ref<number>(0); // 현재 로드가 된 API 갯수
	const contentNum = ref<number>(0);

	function setContentNum(v: number) {
		contentNum.value = v;
	}

	function setBottomSheetNum(v: number) {
		bottomSheetNum.value = v;
	}
	function setFollowUserUuid(v: string) {
		followUserUuid.value = v;
	}
	function setContentsCanvasType(v: CANVAS_MODE | undefined) {
		contentsCanvasType.value = v;
	}
	function setOrderType(v: string) {
		page.value.orderType = v;
	}
	function setResolution(v: RESOLUTION_TYPE) {
		page.value.resolution = v;
	}
	function setCategoryCode(v: string) {
		page.value.categoryCode = v;
	}
	function setAuthToken(v: string) {
		authToken.value = v;
	}
	function setSearchKeyword(v: string) {
		page.value.keywords = v;
	}
	function setScrollPosition(v: number | null) {
		scrollPosition.value = v;
	}
	function setHeaderTitle(v: string) {
		headerTitle.value = v;
	}
	function setSnsRedirectState(v: string) {
		snsRedirectState.value = v;
	}
	function setSavedCrackIdxList(v: Array<string>) {
		savedCrackIdxList.value = v;
	}
	function removeSavedCrackIdx(v: string) {
		savedCrackIdxList.value = savedCrackIdxList.value.filter(idx => idx !== v);
	}
	function addSavedCrackIdx(v: string) {
		if (!savedCrackIdxList.value.includes(v)) {
			savedCrackIdxList.value.push(v);
		}
	}
	function setContentsTargetIdx(v: string | undefined) {
		contentsTargetIdx.value = v;
	}
	function setLoginUserUuid(v: string) {
		loginUserUuid.value = v;
	}
	function setIsHate(v: STATE_YN | null | undefined) {
		isHate.value = v;
	}
	function setIsFav(v: STATE_YN | null | undefined) {
		isFav.value = v;
	}
	function setPopupContentId(v: string) {
		popupContentId.value = v;
	}
	function setPopupShortUrl(v: string) {
		popupShortUrl.value = v;
	}
	function setPopupContentsOptionType(v: string) {
		popupContentsOptionType.value = v;
	}
	function setModifyContentIdx(v: string | undefined) {
		modifyContentIdx.value = v;
	}
	function setModifyContentType(v: any) {
		modifyContentType.value = v;
		if (v) {
			saveLocalData('modifyContentType', v);
		} else {
			removeLocalData('modifyContentType');
		}
	}
	function setContentsTargetInfo(v: ContentEntityDto | undefined) {
		contentsTargetInfo.value = v;
	}

	function setPopupChannelIdx(v: string | undefined) {
		popupChannelIdx.value = v;
	}

	function setPopupReportTargetId(v: string) {
		popupReportTargetId.value = v;
	}

	function setPopupReportTargetType(v: string) {
		popupReportTargetType.value = v;
	}

	function setCopyrightContentIdx(v: string) {
		copyrightContentIdx.value = v;
	}

	function addPageApiTotalCount(v: number) {
		pageApiTotalCount.value += 1;
	}

	function setPageApiTotalCount(v: number) {
		pageApiTotalCount.value = v;
	}

	function addLoadedCount(v: number) {
		loadedCount.value += 1;
	}

	function setLoadedCount(v: number) {
		loadedCount.value = v;
	}

	return {
		bottomSheetNum,
		authToken,
		scrollPosition,
		page,
		headerTitle,
		popupReportTargetId,
		popupReportTargetType,
		savedCrackIdxList,
		contentsTargetIdx,
		loginUserUuid,
		isFav,
		isHate,
		popupContentId,
		popupShortUrl,
		popupChannelIdx,
		popupContentsOptionType,
		modifyContentIdx,
		modifyContentType,
		contentsTargetInfo,
		copyrightContentIdx,
		contentsCanvasType,
		followUserUuid,
		snsRedirectState,
		pageApiTotalCount,
		loadedCount,
		contentNum,
		setContentNum,
		setBottomSheetNum,
		setOrderType,
		setFollowUserUuid,
		setCategoryCode,
		setContentsCanvasType,
		setContentsTargetInfo,
		setModifyContentIdx,
		setModifyContentType,
		setPopupContentsOptionType,
		setPopupContentId,
		setPopupShortUrl,
		setIsFav,
		setIsHate,
		setLoginUserUuid,
		setContentsTargetIdx,
		setSavedCrackIdxList,
		removeSavedCrackIdx,
		addSavedCrackIdx,
		setHeaderTitle,
		setResolution,
		setScrollPosition,
		setAuthToken,
		setSearchKeyword,
		setSnsRedirectState,
		setPopupReportTargetId,
		setPopupReportTargetType,
		setPopupChannelIdx,
		setCopyrightContentIdx,
		setPageApiTotalCount,
		addPageApiTotalCount,
		setLoadedCount,
		addLoadedCount,
	};
});
