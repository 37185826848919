import type { AxiosInstance } from 'axios';
import { getApiHeader } from '@/utils/apiClient';
import { ResponseDto } from './dto/response.dto';
import { SearchShortUrlListDto, ShortUrlEntityDto } from '@/api/dto/common.dto';

/**
 * Short Url 조회
 * @param client
 * @param param
 */
export function getShortUrl(client: AxiosInstance, param: SearchShortUrlListDto): Promise<ResponseDto<ShortUrlEntityDto>> {
	const promiseFn = (fnResolve: (value: ResponseDto<ShortUrlEntityDto>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/common/getShortUrl', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<ShortUrlEntityDto>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
