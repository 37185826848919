// 신고
import { STATE_YN } from '../../types';

export class DefectEntity {
	// 신고일련번호**
	defectSeq = 0;
	// 신고대상고유번호
	defectTargetIdx: string | undefined = undefined;
	// 신고제목
	defectTitle: string | undefined = undefined;
	// 신고내용
	defectBody: string | undefined = undefined;
	// 신고유형코드
	defectTypeCode: string | undefined = undefined;
	// 신고처리상태코드
	defectProcessStateCode: string | undefined = undefined;
	// 신고처리자회원고유번호
	defectProcessorUserIdx: string | undefined = undefined;
	// 신고처리일시
	defectProcessDt: Date | null = null;
	// 등록자회원고유번호
	regrUserIdx: string | undefined = undefined;
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx: string | undefined = undefined;
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	//반환 총갯수
	totalCount = 0;
}
