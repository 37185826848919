import type { AxiosInstance } from 'axios';
import { getApiHeader } from '@/utils/apiClient';
import { ResponseDto } from './dto/response.dto';
import { CategoryEntityDto, SearchCategoryListDto } from './dto/category.dto';

/**
 * 카테고리 리스트 조회
 * @param client
 * @param param
 */
export function getCategoryList(client: AxiosInstance, param: SearchCategoryListDto): Promise<ResponseDto<CategoryEntityDto[]>> {
	const promiseFn = (fnResolve: (value: ResponseDto<CategoryEntityDto[]>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/category/getCategoryList', {
				headers: getApiHeader().headers,
				params: param,
			})
			.then(res => {
				if (res.data.resultCode !== 0) {
					console.error(res);
					fnReject('msg.' + res.data.resultMsg);
				} else {
					fnResolve(new ResponseDto<CategoryEntityDto[]>(res.data));
				}
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
