<script lang="ts">
import { ContentEntityDto } from '@/api/dto/content.dto';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { LICENSE_CODE } from '@/types/index';
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getCopyrightList } from '@/api/asset';
import { AssetCprEntityDto } from '@/api/dto/asset.dto';
import AppConfig from '@/constants';
import { getApiClient } from '@/utils/apiClient';
import ApocLink from '@/components/common/ApocLink.vue';

export default defineComponent({
	name: 'CopyrightPopup',
	components: { ApocLink },
	setup() {
		const storeManager = initStore();
		const { t } = useI18n();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const copyrightList = ref<AssetCprEntityDto[]>([]);

		//다국어, 저작권 종류 필요
		const copyrightInfo: { [key: string]: { typeName: string; link: string } } = {
			'CCL(BY)': {
				typeName: 'CC BY',
				link: 'https://creativecommons.org/licenses/by/4.0/deed.ko',
			},
			'CC BY': {
				typeName: 'CC BY',
				link: 'https://creativecommons.org/licenses/by/4.0/deed.ko',
			},
			BYNC: {
				typeName: 'CC BY-NC',
				link: 'https://creativecommons.org/licenses/by-nc/4.0/deed.ko',
			},
			BYNCND: {
				typeName: 'CC BY-NC-ND',
				link: 'https://creativecommons.org/licenses/by-nc-nd/4.0/deed.ko',
			},
			BYNCSA: {
				typeName: 'CC BY-NC-SA',
				link: 'hhttps://creativecommons.org/licenses/by-nc-sa/4.0/deed.ko',
			},
			BYND: {
				typeName: 'CC BY-ND',
				link: 'https://creativecommons.org/licenses/by-nd/4.0/deed.ko',
			},
			BYSA: {
				typeName: 'CC BY-SA',
				link: 'https://creativecommons.org/licenses/by-sa/4.0/deed.ko',
			},
			DONATION: {
				typeName: '기증(자유이용)',
				link: 'https://gongu.copyright.or.kr/gongu/main/contents.do?menuNo=200092',
			},
			EXPIRED: {
				typeName: '기증(자유이용)',
				link: 'https://gongu.copyright.or.kr/gongu/main/contents.do?menuNo=200091',
			},
			KOGL1: {
				typeName: 'KOGL(제 1유형)',
				link: 'https://www.kogl.or.kr/info/license.do#01-tab',
			},
			KOGL2: {
				typeName: 'KOGL(제 2유형)',
				link: 'https://www.kogl.or.kr/info/license.do#02-tab',
			},
			KOGL3: {
				typeName: 'KOGL(제 3유형)',
				link: 'https://www.kogl.or.kr/info/license.do#03-tab',
			},
			KOGL4: {
				typeName: 'KOGL(제 4유형)',
				link: 'https://www.kogl.or.kr/info/license.do#04-tab',
			},
		};

		const licenseFilter = (code: string) => {
			let license: string = LICENSE_CODE.MEAN_BY_NC_SA;
			if (code === 'CCL(BY)') license = LICENSE_CODE.CC_BY;
			for (const key in LICENSE_CODE) {
				if (key === code) license = code;
			}

			return license;
		};

		const closePopup = (): void => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const openLink = (url: string) => {
			window.open(url);
		};

		const getElementCopyrightList = () => {
			const param = new ContentEntityDto();
			param.contentIdx = storeManager.dataStore.copyrightContentIdx;
			getCopyrightList(apiClient, param).then(res => {
				if (res.resultCode !== 0) {
					window.alert(t('msg.' + res.resultMsg));
				} else {
					if (res.data) {
						copyrightList.value = res.data;
					}
				}
			});

			// store.state.copyrightList.map(elementIdx => {
			// 	const param = new AssetCprEntityDto();
			// 	param.assetIdx = elementIdx;
			// 	getAssetCprDetail(apiClient, param).then(res => {
			// 		if (res.resultCode !== 0) {
			// 			window.alert(t('msg.' + res.resultMsg));
			// 		} else {
			// 			if (res.data) {
			// 				copyrightList.value.push(res.data);
			// 				console.log(copyrightList.value);
			// 			}
			// 		}
			// 	});
			// });
		};

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			copyrightInfo,
			copyrightList,
			closePopup,
			openLink,
		};
	},
});
</script>

<template>
	<!--	<div class="copyright" @mousedown.self.stop.prevent="closePopup">-->
	<div class="copyright-popup">
		<i class="fa fa-light fa-xmark close-btn" @click="closePopup"></i>
		<div class="copyright-header">
			<div class="title">
				<i class="fa fa-light fa-circle-exclamation"></i>
				<span>{{ t('common.source') }}</span>
			</div>
			<div class="list-head">
				<span>*{{ t('popup.elementName') }} | {{ t('popup.elementAuthor') }} | {{ t('popup.elementTerms') }}</span>
			</div>
		</div>
		<div class="copyright-body">
			<ul v-if="copyrightList.filter(cl => cl.usecondBody).length > 0" class="copyright-list">
				<!-- uciCode가 null일 경우 안 띄우기       -->
				<li v-for="item in copyrightList.filter(cl => cl.usecondBody)" :key="item.uciCode">
					<template v-if="item.usecondBody">
						<span class="title-span"> {{ item.assetTitle || '-' }} </span>
						|<span class="author-span"> {{ item.authorName || '-' }} </span>|
						<span class="usecond-body-span">
							<apoc-link
								href=""
								@click="
									openLink(
										copyrightInfo[item.usecondBody ? item.usecondBody : '']
											? copyrightInfo[item.usecondBody ? item.usecondBody : ''].link
											: item.uciCode
											? item.uciCode
											: '',
									)
								"
								>{{ item.usecondBody }}</apoc-link
							></span
						>
					</template>
				</li>
			</ul>
			<div v-else class="empty-copyright">
				<span>{{ t('popup.noSource') }}</span>
			</div>
		</div>
	</div>
	<!--	</div>-->
</template>

<style scoped></style>
