<script setup lang="ts">
import MainFooter from '@/components/footer/MainFooter.vue';
import MegaMenuPlay from '@/components/header/MegaMenuPlay.vue';
import CopyToastPopup from '@/components/popup/CopyToastPopup.vue';
import PopupManager from '@/components/popup/PopupManager.vue';
import AppConfig, { APP_ENV_TYPE } from '@/constants';
import { THIRD_PARTY_TYPE } from '@/stores/common-store';
import { RESOLUTION_TYPE } from '@/stores/data-store';
import { initStore } from '@/stores/store-manager';
import { getApiClient } from '@/utils/apiClient';
import { getMobileOS, loadLocalData, MobileOS } from '@/utils/common-util';
import { getMsTeams } from '@/utils/microsoft-teams';
import { useHead } from '@unhead/vue';
import copy from 'copy-to-clipboard';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView, useRoute, useRouter } from 'vue-router';
import LoadingManager from '@/components/layout/common/LoadingManager.vue';
import { allowBrowserList, BROWSER_TYPE, notAllowInappList } from './types';
import { getSsoUser, ssoLogin } from './utils/sso-login';

useHead({
	title: 'APOC',
	meta: [
		{
			name: 'description',
			content: 'Interactive XR content platform that empowers world to bridge the experiential gap in Metaverse',
		},
	],
});

const { t, locale } = useI18n();
const storeManager = initStore();
const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
const route = useRoute();
const router = useRouter();
const isAuthPage = ref<boolean>(false);
const isSsoLoginPage = ref<boolean>(false);

const swUpdateBarExposeList = ['Contents Detail', 'Contents Direct', 'Contents Short Direct']; // sw 업데이트바가 노출되어야하는 route.name
const showAuthorSwBar = ref(false); // author pwa에서 update가 필요한경우
const directContents = ref<boolean>(false);
const isMainPage = ref<boolean>(false);
const isFullPage = ref<boolean>(false);
const isSearchPage = ref<boolean>(false);
const isTossDirectPage = ref<boolean>(false);
const isNotAllowBrowser = ref<boolean>(false);
const isBgShow = computed(() => isMainPage.value || isSearchPage.value);

// 삼성 물산 서포터즈 링크 이동용 임시 작업
const url = new URL(window.location.href);
const isTempSamsungRedirect = computed(() => url.pathname === '/2410/supporters');
if (isTempSamsungRedirect.value) {
	window.location.href = 'https://forms.gle/BNrErz3Vs3SBGm4E9';
}

const setWindowResolution = () => {
	let device = RESOLUTION_TYPE.PC;
	if (window.innerWidth <= 425) {
		device = RESOLUTION_TYPE.S_MOBILE;
	} else if (window.innerWidth <= 767) {
		device = RESOLUTION_TYPE.MOBILE;
	} else if (window.innerWidth <= 1023) {
		device = RESOLUTION_TYPE.TABLET;
	} else if (window.innerWidth <= 1280) {
		device = RESOLUTION_TYPE.S_PC;
	}
	storeManager.dataStore.setResolution(device);
};

/**
 * 브라우저 타입 조회
 */
const getBrowserType = () => {
	let type: BROWSER_TYPE | undefined = undefined;
	if (typeof window !== 'undefined') {
		const agent = navigator.userAgent.toLowerCase();
		// !!!체크해야하는 순서로 나열되어 있음!!!
		if (agent.includes(BROWSER_TYPE.KAKAO)) {
			type = BROWSER_TYPE.KAKAO;
		} else if (agent.includes(BROWSER_TYPE.NAVER)) {
			type = BROWSER_TYPE.NAVER;
		} else if (agent.includes(BROWSER_TYPE.INSTA)) {
			type = BROWSER_TYPE.INSTA;
		} else if (agent.includes(BROWSER_TYPE.WHALE)) {
			type = BROWSER_TYPE.WHALE;
		} else if (agent.includes(BROWSER_TYPE.SAMSUNG)) {
			type = BROWSER_TYPE.SAMSUNG;
		} else if (agent.includes(BROWSER_TYPE.EDGE)) {
			type = BROWSER_TYPE.EDGE;
		} else if (agent.includes(BROWSER_TYPE.QQ)) {
			type = BROWSER_TYPE.QQ;
		} else if (agent.includes(BROWSER_TYPE.CHROME)) {
			type = BROWSER_TYPE.CHROME;
		} else if (agent.includes(BROWSER_TYPE.FIREFOX)) {
			type = BROWSER_TYPE.FIREFOX;
		} else if (agent.includes(BROWSER_TYPE.SAFARI)) {
			type = BROWSER_TYPE.SAFARI;
		}
	}
	return type;
};

/**
 * Third party 상태 저장
 */
const setThirdPartyType = () => {
	// cafe 24
	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get('tk');
	if (
		// msTeams app 에서 호출했을시
		window.location.ancestorOrigins &&
		window.location.ancestorOrigins.length > 0 &&
		window.location.ancestorOrigins[0].indexOf('teams.microsoft') > -1
	) {
		storeManager.commonStore.setThirdPartyType(THIRD_PARTY_TYPE.MS_TEAMS);
		// init ms teams
		getMsTeams();
	} else if (code) {
		window.location.href = AppConfig.FRONT_HOST + `cafe24?code=${code}`;
	} else {
		storeManager.commonStore.setThirdPartyType(THIRD_PARTY_TYPE.NONE);
	}
	// window.alert(storeManager.stateStore.thirdPartyType);
};

const handleResize = () => {
	setWindowResolution();
};

const leadOtherBrowser = async () => {
	// 24.07.04 수정
	// 1. 기존처럼(24.07.04 이전) 카카오, 네이버, 인스타 인 경우 타 브라우저 유도
	// 2. chrome, safari, firefox, samsung internet, edge, naver whale, qq 가 아닌 모든 곳에서도 타 브라우저 유도
	if (typeof navigator !== 'undefined') {
		const curBrowserType = getBrowserType();
		const curMobileOs = getMobileOS();

		const showNoticeMobile = (showBottom?: boolean) => {
			const bodyEl = document.getElementById('app');
			if (bodyEl) {
				const noticeEl = document.createElement('div');
				noticeEl.setAttribute('id', 'notice-inapp');
				// 중앙영역(로고, 설명, URL 버튼)
				const drawCenter = () => {
					const d1 = document.createElement('div');
					d1.classList.add('wrap-center');
					// 아폭로고
					const noticeLogo = document.createElement('img');
					noticeLogo.setAttribute('src', `/assets/images/icon-logo-round.webp`);
					noticeLogo.setAttribute('srcset', `/assets/images/icon-logo-round@2x.webp 2x, /assets/images/icon-logo-round@3x.webp 3x`);
					// 로고 하단에 타 브라우저 유도 설명글
					const noticeText = document.createElement('img');
					noticeText.setAttribute('src', `/assets/images/notice-text-${locale}.webp`);
					noticeText.setAttribute('srcset', `/assets/images/notice-text-${locale}@2x.webp 2x, /assets/images/notice-text-${locale}@3x.webp 3x`);
					// 설명 하단에 버튼 영역(브라우저 오픈 및 URL 복사)
					const btnDiv = document.createElement('div');

					// '타 브라우저로 열기' 버튼
					const btnExtern = document.createElement('img');
					btnExtern.classList.add('mr-2');
					btnExtern.setAttribute('src', `/assets/images/noteice-btn-extern-${locale}.webp`);
					btnExtern.setAttribute(
						'srcset',
						`/assets/images/noteice-btn-extern-${locale}@2x.webp 2x, /assets/images/noteice-btn-extern-${locale}@3x.webp 3x`,
					);
					btnExtern.addEventListener('click', () => {
						// window.location.href = window.location.href.replace(/^https/, 'googlechromes');
						// window.location.href = 'kakaotalk://inappbrwser/close';
						// const win = window.open(window.location.href, '_system');
						// win?.focus();
						const target_url = window.location.href;

						if (curMobileOs === MobileOS.ANDROID) {
							try {
								window.location.href = 'intent://' + target_url.replace(/https?:\/\//i, '') + '#Intent;scheme=http;package=com.android.chrome;end';
							} catch (e) {
								console.error(e);
								copy(window.location.href);
								window.alert(t('msg.ALERT_PLEASE_URL_PASTE'));
							}
						} else if (curMobileOs === MobileOS.IOS) {
							if (curBrowserType === BROWSER_TYPE.KAKAO) {
								location.href = 'kakaotalk://web/openExternal?url=' + encodeURIComponent(target_url);
							} else {
								copy(window.location.href);
								window.alert(t('msg.ALERT_PLEASE_URL_PASTE_WHEN_BROWSER_OPEN'));
								window.location.href = 'x-web-search://?';
							}
						} else {
							copy(window.location.href);
							window.alert(t('msg.ALERT_PLEASE_URL_PASTE'));
						}
					});
					// 'URL 복사하기' 버튼
					const btnCopyUrl = document.createElement('img');
					btnCopyUrl.setAttribute('src', `/assets/images/notice-btn-copy-url-${locale}.webp`);
					btnCopyUrl.setAttribute(
						'srcset',
						`/assets/images/notice-btn-copy-url-${locale}@2x.webp 2x, /assets/images/notice-btn-copy-url-${locale}@3x.webp 3x`,
					);
					btnCopyUrl.addEventListener('click', () => {
						copy(window.location.href);
						window.alert(t('msg.ALERT_PLEASE_URL_PASTE'));
					});

					btnDiv.appendChild(btnExtern);
					btnDiv.appendChild(btnCopyUrl);

					d1.appendChild(noticeLogo);
					d1.appendChild(document.createElement('div'));
					d1.appendChild(noticeText);
					// 모바일에서만 버튼 노출하도록
					// 네이버 블로그에서 모바일 캐치 안되는 경우 있음. showBottom 사용
					if (navigator.userAgent.toLocaleLowerCase().includes('mobile') || showBottom) d1.appendChild(btnDiv);

					noticeEl.appendChild(d1);
				};

				// 하단영역(모바일 기기 자체의 타 브라우저 열기 아이콘 클릭 유도)
				const drawBottom = () => {
					let noticeBottomIconSrc = '';

					// 네이버
					if (curBrowserType === BROWSER_TYPE.NAVER) {
						const isNaverBlog = navigator.userAgent.toLowerCase().includes('inapp; blog');
						if (isNaverBlog) {
							if (curMobileOs === MobileOS.ANDROID) {
								// 클릭 유도 아이콘 없음 + 지구본 아이콘과 '기본 브라우저' 멘트
								noticeBottomIconSrc = `/assets/images/notice-bottom-naver-blog-android-${locale}`;
							} else if (curMobileOs === MobileOS.IOS) {
								// 클릭 유도 아이콘 있음(확장 아이콘) + 아이콘 없이 '기본 브라우저' 멘트
								noticeBottomIconSrc = `/assets/images/notice-bottom-naver-blog-iphone-${locale}`;
							}
						} else {
							if (curMobileOs !== MobileOS.OTHER) {
								// 클릭 유도 아이콘 있음(햄버거 아이콘) + 지구본 아이콘과 '기본 브라우저' 멘트
								noticeBottomIconSrc = `/assets/images/notice-bottom-naver-${locale}`;
							}
						}
						// 인스타
					} else if (curBrowserType === BROWSER_TYPE.INSTA) {
						if (curMobileOs === MobileOS.ANDROID) {
							// 클릭 유도 아이콘 있음(케밥 아이콘) + 아이콘 없이 '크롬 혹은 삼성브라우저' 멘트
							noticeBottomIconSrc = `/assets/images/notice-bottom-insta-android-${locale}`;
						} else if (curMobileOs === MobileOS.IOS) {
							// 클릭 유도 아이콘 있음(케밥 아이콘) + 아이콘 없이 '브라우저' 멘트
							noticeBottomIconSrc = `/assets/images/notice-bottom-insta-iphone-${locale}`;
						}
						// 카카오
					} else {
						if (curMobileOs === MobileOS.ANDROID) {
							// 클릭 유도 아이콘 있음(케밥 아이콘) + 아이콘 없이 '다른 브라우저' 멘트
							noticeBottomIconSrc = `/assets/images/notice-bottom-android-${locale}`;
						} else if (curMobileOs === MobileOS.IOS) {
							// 클릭 유도 아이콘 있음(업로드 같은 아이콘) + 'Safari로 열기' 멘트
							noticeBottomIconSrc = `/assets/images/notice-bottom-ios2-${locale}`;
						}
					}

					if (noticeBottomIconSrc) {
						const noticeBottmEl = document.createElement('div');
						noticeBottmEl.classList.add('wrap-bottom');
						if (curBrowserType === BROWSER_TYPE.INSTA) noticeBottmEl.classList.add('top');

						const noticeBottomIcon = document.createElement('img');
						noticeBottomIcon.setAttribute('src', `${noticeBottomIconSrc}.webp`);
						noticeBottomIcon.setAttribute('srcset', `${noticeBottomIconSrc}@2x.webp 2x, ${noticeBottomIconSrc}@3x.webp 3x`);
						noticeBottmEl.appendChild(noticeBottomIcon);
						noticeEl.appendChild(noticeBottmEl);
					}
				};

				drawCenter();
				if (showBottom) drawBottom();

				bodyEl.appendChild(noticeEl);
			}
		};

		const showNoticePc = () => {
			const bodyEl = document.getElementById('app');
			if (bodyEl) {
				const noticeEl = document.createElement('div');
				noticeEl.setAttribute('id', 'notice-inapp');
				noticeEl.classList.add('pc');

				const d1 = document.createElement('div');
				d1.classList.add('pc-notice-wrapper');

				const noticeContent = document.createElement('img');
				noticeContent.setAttribute('src', `/assets/images/notice-other-browser-pc-${locale}.webp`);
				noticeContent.setAttribute(
					'srcset',
					`/assets/images/notice-other-browser-pc-${locale}@2x.webp 2x, /assets/images/notice-other-browser-pc-${locale}@3x.webp 3x`,
				);
				d1.appendChild(noticeContent);
				noticeEl.appendChild(d1);

				bodyEl.appendChild(noticeEl);
			}
		};

		if (!curBrowserType || (curBrowserType && (notAllowInappList.includes(curBrowserType) || !allowBrowserList.includes(curBrowserType)))) {
			isNotAllowBrowser.value = true;
			if (
				!(curBrowserType && notAllowInappList.includes(curBrowserType)) &&
				!navigator.userAgent.toLocaleLowerCase().includes('mobile') &&
				window.innerWidth > 767
			) {
				showNoticePc();
			} else {
				showNoticeMobile(curBrowserType && notAllowInappList.includes(curBrowserType));
			}
		}
	}
};

const hasRouteName = computed(() => {
	return (
		route.name !== '' &&
		route.name !== 'Contents Direct' &&
		route.name !== 'IndependenceHall' &&
		route.name !== 'Contents Short Direct' &&
		route.name !== 'IndependenceHallSurvey_1' && // survey 푸터 막기
		route.name !== 'IndependenceHallSurvey_2' &&
		route.name !== 'IndependenceHall2' &&
		route.name !== 'IndependenceHall3' &&
		route.name !== 'Reservation' &&
		route.name !== 'apocVip' &&
		typeof route.name !== 'undefined'
	);
});

const handleLocalStorage = () => {
	if (!(loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER) && loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN))) {
		storeManager.dataStore.setAuthToken('');
	}
};

/**
 * 저작도구에서 넘어온 message handler
 * @param e
 */
const handleMessageReceived = (e: MessageEvent) => {
	if (AppConfig.AUTHOR_HOST.indexOf(e.origin) > -1) {
		if (e.data.needSwUpdate) {
			// 저작도구에서 Pwa 업데이트를 요청한 경우
			if (route.name && swUpdateBarExposeList.indexOf(route.name as string) > -1) {
				// route.name이 특정페이지에 있을 경우
				const bodyEl = window.document.getElementsByTagName('body');
				if (bodyEl && bodyEl.length > 0) bodyEl[0].classList.add('sw-need-update');
				showAuthorSwBar.value = true;
			}
		} else if (e.data.doSwUpdate) {
			// 업데이트 실행 및 완료한경우?
			const bodyEl = window.document.getElementsByTagName('body');
			if (bodyEl && bodyEl.length > 0) bodyEl[0].classList.remove('sw-need-update');
			showAuthorSwBar.value = false;
		}
	}
};

// Ms app 에서 저작도구에서 넘어가는 return
const MsTeamsAuth = () => {
	getMsTeams()
		.getContext()
		.then(res => {
			if (res.page.subPageId) {
				const t = JSON.parse(res.page.subPageId);
				if (!t) return;
				if (t.needLogin) {
					ssoLogin();
				} else if (t.logout) {
					// 저작도구 로그아웃 성공시
				}
			}
		});
};

watch(
	() => route.path,
	() => {
		isFullPage.value = route.path.indexOf('reservation') > -1;
		isMainPage.value = route.path === '/';
		isSearchPage.value = route.path === '/search';
		isSsoLoginPage.value = route.path === '/login/success';
		isTossDirectPage.value = route.path === '/2410/toss/d' || route.path === '/2410/toss/d2' || route.path === '/2410/toss/d/success';
		window.scrollTo(0, 0);
	},
);

onMounted(() => {
	// sso 자동 로그인
	if (route.path !== '/sso/logout') {
		getSsoUser(storeManager);
	}

	directContents.value =
		(('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('direct') > -1 &&
			!(('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('login') > -1)) ||
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('IndependenceHall') > -1 ||
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('reservation') > -1 ||
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('apocVip') > -1 ||
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('heycomehere') > -1 ||
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').substring(0, 2) === 'd/' ||
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('survey_') > -1; // survey일때 헤더 안보이는 조건 추가

	storeManager.stateStore.setDirectContents(directContents.value);
	isAuthPage.value =
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').toLowerCase().indexOf('auth') > -1 &&
		!(('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('login') > -1);

	window.addEventListener('message', handleMessageReceived);
	window.addEventListener('resize', handleResize);

	setWindowResolution();
	// closeKakaoApp();
	if (
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('reservation') < 0 &&
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('heycomehere') < 0 &&
		('' + window.location.href).replace(AppConfig.FRONT_HOST, '').indexOf('apocVip') < 0
	)
		leadOtherBrowser();

	const url = new URL(window.location.href);
	if (AppConfig.ENV === APP_ENV_TYPE.PROD && url.host !== 'www.apoc.day') window.location.href = 'https://www.apoc.day';

	window.addEventListener('storage', handleLocalStorage);

	// third party 호출 여부
	setThirdPartyType();

	// ms 호출시 대응
	if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.MS_TEAMS) MsTeamsAuth();

	isSsoLoginPage.value = route.path === '/login/success';
	isFullPage.value = route.path.indexOf('reservation') > -1 || route.path.indexOf('apocVip') > -1;
	isMainPage.value = route.path === '/';
	isSearchPage.value = route.path === '/search';
	isTossDirectPage.value = route.path === '/2410/toss/d' || route.path === '/2410/toss/d2' || route.path === '/2410/toss/d/success';
});

onUnmounted(() => {
	window.removeEventListener('message', handleMessageReceived);
	window.removeEventListener('resize', handleResize);
	window.removeEventListener('storage', handleLocalStorage);
});
</script>
<template>
	<div>
		<LoadingManager />
	</div>
	<div v-show="isBgShow" class="app-bg"></div>
	<!--  셀렉트 옵션 리스트 영역-->
	<div id="apoc-select-options"></div>
	<popup-manager v-if="!isAuthPage"></popup-manager>
	<copy-toast-popup v-if="storeManager.stateStore.popupCopyState && !isAuthPage" />
	<MegaMenuPlay v-if="!isSsoLoginPage && !isAuthPage && !directContents && !isTempSamsungRedirect && !isTossDirectPage" />
	<MegaMenu />
	<div
		v-if="!isNotAllowBrowser"
		class="page"
		:class="{
			'full-page': isFullPage,
			'search-bar-open-page': storeManager.stateStore.isOpenSearchBar,
		}">
		<router-view :key="$route.path" />
	</div>
	<main-footer v-if="hasRouteName && !isAuthPage && !directContents && !isTossDirectPage" />
	<div v-if="!directContents && storeManager.commonStore.isLoading" ref="axiosWrapper" class="axios-loading">
		<div ref="loader" class="loader"></div>
	</div>
</template>

<style lang="scss">
@import 'assets/css/app.scss';
</style>
