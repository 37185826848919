<script lang="ts">
import { defineComponent, ref } from 'vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';

export default defineComponent({
	name: 'ApocCollapse',
	components: { ApocImageSet },

	setup() {
		const isOpenCollapse = ref<boolean>(false);

		return {
			isOpenCollapse,
			onClickOpen: () => {
				isOpenCollapse.value = !isOpenCollapse.value;
			},
		};
	},
});
</script>

<template>
	<div class="apoc-collapse" @click="onClickOpen">
		<slot name="label" />
		<apoc-image-set class="collapse-arrow" :class="{ active: isOpenCollapse }" src="/assets/images/common/icons/arrow-down2.svg" />
		<div v-if="isOpenCollapse" class="apoc-collapse-body">
			<slot name="body" />
		</div>
	</div>
</template>

<style scoped></style>
