<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import type { PropType } from 'vue';
import AppConfig from '@/constants';

export default defineComponent({
	name: 'ApocSvg',
	props: {
		src: {
			type: String as PropType<string>,
			required: true,
		},
	},
	setup(props) {
		const parentElement = ref<HTMLElement | null>(null);

		const generateSvg = () => {
			if (parentElement.value) {
				const request = new XMLHttpRequest();
				request.open('GET', AppConfig.FRONT_HOST.replace('/#/', '/') + props.src, true);
				request.onload = () => {
					if (request.status === 200) {
						const domParser = new DOMParser();
						const result = domParser.parseFromString(request.responseText, 'text/xml');
						const loadedSVG = result.getElementsByTagName('svg')[0];

						if (!loadedSVG) {
							console.error(`svg element not found\n${request.responseURL}`);
							return;
						}
						if (parentElement.value) {
							parentElement.value.appendChild(loadedSVG);
						}
					} else if (request.status === 404) {
						console.error(`svg src not found.\n${request.responseURL}`);
					} else {
						console.error(`error!\n${request.responseURL}\nstatus ${request.status}`);
					}
				};
				request.onerror = () => {
					console.error('XML Http Request error');
				};
				request.send();
			}
		};

		onMounted(() => {
			generateSvg();
		});

		return {
			parentElement,
		};
	},
});
</script>

<template>
	<div ref="parentElement" class="apoc-svg"></div>
</template>

<style scoped></style>
