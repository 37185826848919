<script lang="ts">
import { deleteContent, getContentDetail } from '@/api/content';
import { ContentEntityDto } from '@/api/dto/content.dto';
import { ContentEntity } from '@/api/model/content.entity';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import ApocLink from '@/components/common/ApocLink.vue';
import AppConfig from '@/constants';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { getApiClient } from '@/utils/apiClient';
import { goAuthor, goAuthorContextInterface } from '@/utils/apoc-utils';
import { ShareType, generateShortShareLink, loadLocalData } from '@/utils/common-util';
import moment from 'moment';
import { computed, defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
	name: 'ContentsSelectModal',
	components: { ApocImageSet, ApocLink },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const compCrackIdx = computed(() => storeManager.dataStore.popupContentId); //콘텐츠 IDX
		const compCrackUrl = computed(() => storeManager.dataStore.popupShortUrl); //콘텐츠 shortUrl(For. 공유하기)
		const isNotTemplate = ref<boolean>(true); //템플릿 유무

		//팝업 닫기
		const closePopup = (): void => {
			storeManager.dataStore.setPopupContentId('');
			storeManager.dataStore.setPopupShortUrl('');
			storeManager.dataStore.setContentsTargetIdx('');
			storeManager.dataStore.setContentsTargetInfo(undefined);
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		// 분석 클릭
		const onClickAnalysis = () => {
			window.alert(t('msg.ALERT_PREPARE_SERVICE'));
			closePopup();
		};

		// 콘텐츠 정보 클릭
		const onClickContentDetail = () => {
			// 팝업창 닫기
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			setTimeout(() => {
				storeManager.stateStore.setPopupMode(POPUP_MODE.CONTENTS_POPUP);
			}, 200);
		};

		/**
		 * 콘텐츠 이름 변경 기능
		 * @author lumbar
		 * @date 240109
		 */
		const onClickChangeName = async () => {
			const deleteCheck = await contentDeleteCheck(compCrackIdx.value);
			if (!deleteCheck) return;
			// 팝업창 닫기
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			setTimeout(() => {
				storeManager.dataStore.setContentsTargetIdx(compCrackIdx.value);
				storeManager.stateStore.setPopupMode(POPUP_MODE.CONTENTS_RENAME);
			}, 200);
		};

		/**
		 * 수정하기 (저작도구 연결)
		 * @author dopamine
		 * @date 220404
		 */
		const onClickEdit = async () => {
			const deleteCheck = await contentDeleteCheck(compCrackIdx.value);
			if (!deleteCheck) return;
			storeManager.dataStore.setModifyContentIdx(compCrackIdx.value);
			storeManager.stateStore.setOpenAuthor(true);

			const param: goAuthorContextInterface = {
				authorUrl: `${AppConfig.AUTHOR_HOST}author?idx=${compCrackIdx.value}&lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}`,
				ctx: JSON.stringify({ id: compCrackIdx.value, lang: loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG) }),
				target: `content_${compCrackIdx.value}` + (!isNotTemplate.value ? `&current_time=${moment()}` : ''),
			};
			await goAuthor(param, storeManager);
		};

		/**
		 * 공유하기 기능
		 * @param type
		 * @author Tibia
		 * @date 220427
		 * @modify pubis 221005
		 * @modify bigtoe 230519
		 */
		const onClickShare = async () => {
			const deleteCheck = await contentDeleteCheck(compCrackIdx.value);
			if (!deleteCheck) return;
			storeManager.dataStore.setPopupContentId(compCrackIdx.value);
			storeManager.stateStore.setPopupMode(POPUP_MODE.CONTENT_SHARE_POPUP);
			closePopup();
		};

		// 콘텐츠가 삭제되었는지 확인
		const contentDeleteCheck = async (contentIdx: string) => {
			const param = new ContentEntityDto();
			param.contentIdx = contentIdx as string;
			const res = await getContentDetail(apiClient, param);
			if (res.resultCode !== 0) {
				window.alert(t('msg.' + res.resultMsg));
				closePopup();
				return null;
			}
			return res;
		};

		/**
		 * 콘텐츠 삭제하기
		 * @author dopamine
		 * @date 220404
		 */
		const onClickDelete = async () => {
			const deleteCheck = await contentDeleteCheck(compCrackIdx.value);
			if (!deleteCheck) return;
			setTimeout(() => {
				storeManager.dataStore.setContentsTargetIdx(compCrackIdx.value);
				if (window.confirm(t('msg.CONFIRM_DELETE_CONTENTS'))) {
					const param = new ContentEntity();
					param.contentIdx = compCrackIdx.value as string;
					param.channelIdx = undefined;
					deleteContent(apiClient, param).then(res => {
						if (res.resultCode === 0) {
							window.alert(t(`msg.RESULT_DELETE`));
							//삭제 후 리스트 조회 위해 새로고침 하도록 함. 추후 변경될 수도 있음
							window.location.reload();
						} else {
							window.alert(t('msg.' + res.resultMsg));
						}
					});
				}
				storeManager.dataStore.setContentsTargetIdx(undefined);
				closePopup();
			}, 200);
		};

		onMounted(() => {
			document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			t,
			closePopup,
			onClickAnalysis,
			onClickContentDetail,
			onClickEdit,
			onClickChangeName,
			onClickShare,
			onClickDelete,
		};
	},
	computed: {
		ShareType() {
			return ShareType;
		},
	},
});
</script>
<template>
	<div class="contents-select-modal">
		<div class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<apoc-link href="#" @click="onClickContentDetail">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_pencil-navy.svg"></apoc-image-set>
				{{ t('layout.myContents.modContentsInfo') }}
			</apoc-link>
			<apoc-link href="#" @click="onClickChangeName">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_title-navy.svg"></apoc-image-set>
				{{ t('layout.myContents.modTitle') }}
			</apoc-link>
			<apoc-link href="#" @click="onClickAnalysis">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_analysis-navy.svg"></apoc-image-set>
				{{ t('layout.myContents.analysis') }}
			</apoc-link>
			<!-- <apoc-link href="#" @click="onClickShare(ShareType.COPY_LINK)">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_share-navy.svg"></apoc-image-set>
				{{ t('layout.myContents.shareContents') }}
			</apoc-link> -->
			<apoc-link href="#" class="to-studio" @click="onClickEdit">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_window-navy.svg"></apoc-image-set>
				{{ t('layout.myContents.toStudio') }}
			</apoc-link>
			<apoc-link href="#" @click="onClickDelete">
				<apoc-image-set src="/assets/images/common/icons/mypage/icon_trash-navy.svg"></apoc-image-set>
				{{ t('layout.myContents.delete') }}
			</apoc-link>
			<div class="border-line"></div>
			<apoc-link href="#" @click="closePopup">
				<apoc-image-set src="/assets/images/icon-category/close-icon-navy.svg"></apoc-image-set>
				{{ t('common.cancel') }}
			</apoc-link>
		</div>
	</div>
</template>
